import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { LanguageLoaderService } from 'src/app/_services/languageLoader.service';
import { LoginState } from '../../_shared/classes/_enums';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CustomTranslateService } from 'src/app/_services/customtranslate.service';
import { DataUserManagementService } from 'src/app/_services/data.usermanagement.service';
import { Customer } from 'src/app/_shared/classes/Auth';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  @ViewChild('sidenav') sidenav: MatSidenavModule;
  @ViewChild('searchbar') searchbar: ElementRef;
  
  loggedIn$: Observable<boolean>
  userDetails$ = new BehaviorSubject<Customer>(new Customer())
  color$ = new BehaviorSubject<string>("#ffffff")

  constructor(
    public dataUserManagement: DataUserManagementService,
    private translate: TranslateService,
    private langLoader: LanguageLoaderService,
    private translateService: CustomTranslateService,
  ) {     
    
    this.loggedIn$ = this.dataUserManagement.LoginState
    this.dataUserManagement.UserDetails.subscribe({ next: (response) => {      
      if(response != null)  this.userDetails$.next(response)
    } })


  }

  LoginState = LoginState

  isExpanded = true
  showSubmenu: boolean = false
  //isShowing = false;
  //showSubSubMenu: boolean = false;
  
  searchText = ""
  toggleSearch: boolean = false

  languagesCodes: string[] = ["de", "en"] //TODO
  currentLanguage: string = this.languagesCodes[0] //TODO

  searchSections = ["all", "analyes", "docu", "settings"]
  searchSection: string = this.searchSections[0] 

  isExpandedTmp = false
  mouseenter() {
    if (!this.isExpanded) {
      this.isExpanded = true;
      this.isExpandedTmp = true;
    }
  }

  mouseleave() {
    if (this.isExpandedTmp) {
      this.isExpanded = false;
      this.isExpandedTmp = false;
    }
  }

  onSetLanguage(lang: string){
    this.translateService.currentLanguage = lang
    this.translate.use(lang)
    this.langLoader.onChangeLanguage.emit()
  }

  openSearch() {
    this.toggleSearch = true;
    //this.searchbar.nativeElement.focus();
  }
  searchClose() {
    this.searchText = '';
    this.toggleSearch = false;
  }
}
