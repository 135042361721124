import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cfg-card',
  templateUrl: './cfg-card.component.html',
  styleUrls: ['./cfg-card.component.scss']
})
export class CfgCardComponent {
  @Input() title: string
  @Input() description: string
  @Input() content: string
}
