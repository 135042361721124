import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConfigTableDef, ConfigTableColDef, ConfigTableColType, ConfigTableComponent } from 'src/app/_khLib/components/config-table/config-table.component';
import { DynamicPipeType } from 'src/app/_pipes/dynamic.pipe';
import { DataDynamicContentService } from 'src/app/_services/data.dynamicContent.service';
import { DataSettingService } from 'src/app/_services/data.settings.service';
import { DataUserManagementService } from 'src/app/_services/data.usermanagement.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ConfigTableColSelectionType } from 'src/app/_services/helper.service';
import { RestApiService } from 'src/app/_services/restApi.service';
import { ApiResponse } from 'src/app/_shared/classes/ApiResponse';
import { Customer } from 'src/app/_shared/classes/Auth';
import { DynamicMedia, DynamicPage, DynamicTemplate, Menu, MenuEntry } from 'src/app/_shared/classes/DynamicContent';

@Component({
  selector: 'app-designer',
  templateUrl: './designer.component.html',
  styleUrls: ['./designer.component.scss']
})
export class DesignerComponent implements OnInit {

  //#region Tbl Menu
  cfgTableMenu = new ConfigTableDef<Menu>(Menu, {
    tblName: 'menu',
    showTblnameInTitle: true,
    showDetailDialog: false,
    infoDialogTitleFunction: (e) => { return e.firstname + ' ' + e.name},
    cols: [
      new ConfigTableColDef({name: 'id',       type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'name',     type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),        
    ], 
    menuButtons: [

    ],   
    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createMenu(c),
    onCreate: (object, result) => this.dynamicContent.synchMenu(),
    onCreateTextLoading: (): string => 'menuInfoCreate',
    onCreateTextLoaded: (): string => 'menuInfoCreated',
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.changeMenu(c),
    onEdit: (object, result) => this.dynamicContent.synchMenu(),
    onEditTextLoading: (): string => 'menuInfoEdit',
    onEditTextLoaded: (): string => 'menuInfoEdited',

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteMenu(ids),
    onDelete: (object, result) => this.dynamicContent.synchMenu(),
    onDeleteTextLoading: (): string => 'menuInfoDelete',
    onDeleteTextLoaded: (): string => 'menuInfoDeleted',

    listNameColumnFunction: (d) => d.name,
  })
  cfgTableMenuData = this.dynamicContent.menues
  @ViewChild('tblMenu') tblMenu: ConfigTableComponent
  //#endregion

  //#region Tbl MenuEntries
  cfgTableMenuEntries = new ConfigTableDef<MenuEntry>(MenuEntry, {
    tblName: 'menuEntry',
    showTblnameInTitle: true,
    showDetailDialog: false,
    infoDialogTitleFunction: (e) => { return e.firstname + ' ' + e.name},
    cols: [
      new ConfigTableColDef({name: 'id',        type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'menuId',    type: ConfigTableColType.Selection, selectionType: ConfigTableColSelectionType.Menu, showInTable: true, pipe: DynamicPipeType.Menu}),        
      new ConfigTableColDef({name: 'pathName',  type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),        
      new ConfigTableColDef({name: 'titleLang', type: ConfigTableColType.TranslationEditor, showInTable: true, filterable: false, pipe: DynamicPipeType.None}),        
      new ConfigTableColDef({name: 'icon',      type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),   
      new ConfigTableColDef({name: 'displayOrderId',      type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),   
    ], 
    showInlineEditButton: true,
    menuButtons: [

    ],   
    createBeforeEditData: true,
    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createMenuEntry(c),
    onCreate: (object, result) => this.dynamicContent.synchMenuEntry(),
    onCreateTextLoading: (): string => 'menuEntryInfoCreate',
    onCreateTextLoaded: (): string => 'menuEntryInfoCreated',
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.changeMenuEntry(c),
    onEdit: (object, result) => this.dynamicContent.synchMenuEntry(),
    onEditTextLoading: (): string => 'menuEntryInfoEdit',
    onEditTextLoaded: (): string => 'menuEntryInfoEdited',

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteMenuEntry(ids),
    onDelete: (object, result) => this.dynamicContent.synchMenuEntry(),
    onDeleteTextLoading: (): string => 'menuEntryInfoDelete',
    onDeleteTextLoaded: (): string => 'menuEntryInfoDeleted',

    onSynch: () => this.dynamicContent.synchMenuEntry(),

    listNameColumnFunction: (d) => d.pathName,
  })
  cfgTableMenuEntriesData = this.dynamicContent.menuEntries
  @ViewChild('tblMenuEntries') tblMenuEntries: ConfigTableComponent
  //#endregion

  //#region Tbl Pages
  cfgTablePages = new ConfigTableDef<DynamicPage>(DynamicPage, {
    tblName: 'dynamicPage',
    showTblnameInTitle: true,
    showDetailDialog: false,
    //infoDialogTitleFunction: (e) => { return e.name},
    cols: [
      new ConfigTableColDef({name: 'id',        type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'name',    type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),        
      new ConfigTableColDef({name: 'pathName',    type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),        
      new ConfigTableColDef({name: 'contentLang',    type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),         
      
    ], 
    showInlineEditButton: true,
    menuButtons: [

    ],   
    //createBeforeEditData: true,
    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createDynamicPage(c),
    onCreate: (object, result) => this.dynamicContent.synchDynamicPage(),
    onCreateTextLoading: (): string => 'dynamicPageInfoCreate',
    onCreateTextLoaded: (): string => 'dynamicPageInfoCreated',
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.changeDynamicPage(c),
    onEdit: (object, result) => this.dynamicContent.synchDynamicPage(),
    onEditTextLoading: (): string => 'dynamicPageInfoEdit',
    onEditTextLoaded: (): string => 'dynamicPageInfoEdited',

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteDynamicPage(ids),
    onDelete: (object, result) => this.dynamicContent.synchDynamicPage(),
    onDeleteTextLoading: (): string => 'dynamicPageInfoDelete',
    onDeleteTextLoaded: (): string => 'dynamicPageInfoDeleted',

    onSynch: () => this.dynamicContent.synchDynamicPage(),

    listNameColumnFunction: (d) => d.pathName,
  })
  cfgTablePagesData = this.dynamicContent.pages
  @ViewChild('tblPages') tblPages: ConfigTableComponent
  //#endregion
  
  //#region Tbl Template
  cfgTemplates = new ConfigTableDef<DynamicTemplate>(DynamicTemplate, {
    tblName: 'dynamicTemplate',
    showTblnameInTitle: true,
    showDetailDialog: false,
    //infoDialogTitleFunction: (e) => { return e.name},
    cols: [
      new ConfigTableColDef({name: 'id',        type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'name',    type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),        
      new ConfigTableColDef({name: 'contentLang',    type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),    
    ], 
    showInlineEditButton: true,
    menuButtons: [

    ],   
    //createBeforeEditData: true,
    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createDynamicTemplate(c),
    onCreate: (object, result) => this.dynamicContent.synchDynamicTemplate(),
    onCreateTextLoading: (): string => 'dynamicTemplateInfoCreate',
    onCreateTextLoaded: (): string => 'dynamicTemplateInfoCreated',
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.changeDynamicTemplate(c),
    onEdit: (object, result) => this.dynamicContent.synchDynamicTemplate(),
    onEditTextLoading: (): string => 'dynamicTemplateInfoEdit',
    onEditTextLoaded: (): string => 'dynamicTemplateInfoEdited',

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteDynamicTemplate(ids),
    onDelete: (object, result) => this.dynamicContent.synchDynamicTemplate(),
    onDeleteTextLoading: (): string => 'dynamicTemplateInfoDelete',
    onDeleteTextLoaded: (): string => 'dynamicTemplateInfoDeleted',

    onSynch: () => this.dynamicContent.synchDynamicTemplate(),

    listNameColumnFunction: (d) => d.name,
  })
  cfgTemplatesData = this.dynamicContent.templates
  @ViewChild('tblTemplates') tblTemplates: ConfigTableComponent
  //#endregion

  //#region Tbl Media
  cfgMedias = new ConfigTableDef<DynamicMedia>(DynamicMedia, {
    tblName: 'dynamicMedia',
    showTblnameInTitle: true,
    showDetailDialog: false,
    //infoDialogTitleFunction: (e) => { return e.name},
    cols: [
      new ConfigTableColDef({name: 'id',        type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'name',    type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),        
      new ConfigTableColDef({name: 'url',  type: ConfigTableColType.Image, showInTable: true, filterable: false, pipe: DynamicPipeType.Image, onSendFileApi: (i,e) => this.restApiService.createDynamicMediaFile(i,e)}),    
    ], 
    showInlineEditButton: true,
    menuButtons: [

    ],   
    //createBeforeEditData: true,
    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createDynamicMedia(c),
    onCreate: (object, result) => this.dynamicContent.synchDynamicMedia(),
    onCreateTextLoading: (): string => 'dynamicMediaInfoCreate',
    onCreateTextLoaded: (): string => 'dynamicMediaInfoCreated',
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.changeDynamicMedia(c),
    onEdit: (object, result) => this.dynamicContent.synchDynamicMedia(),
    onEditTextLoading: (): string => 'dynamicMediaInfoEdit',
    onEditTextLoaded: (): string => 'dynamicMediaInfoEdited',

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteDynamicMedia(ids),
    onDelete: (object, result) => this.dynamicContent.synchDynamicMedia(),
    onDeleteTextLoading: (): string => 'menuEntryInfoDelete',
    onDeleteTextLoaded: (): string => 'menuEntryInfoDeleted',

    onSynch: () => this.dynamicContent.synchDynamicMedia(),

    listNameColumnFunction: (d) => d.name,
  })
  cfgMediasData = this.dynamicContent.medias
  @ViewChild('tblMedias') tblMedias: ConfigTableComponent
  //#endregion

  
  constructor(
    private dynamicContent: DataDynamicContentService,
    private http: HttpClient,
    private errorService: ErrorService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private restApiService: RestApiService,
    private dataSettings: DataSettingService,
    private translateService: TranslateService){
      

  }

  ngOnInit(): void {
    this.dynamicContent.OnMenuChanged.subscribe({ next: (response) => this.tblMenu.refreshData(response)})
    this.dynamicContent.OnMenuEntryChanged.subscribe({ next: (response) => this.tblMenuEntries.refreshData(response)})
    this.dynamicContent.OnDynamicTemplateChanged.subscribe({ next: (response) => this.tblTemplates.refreshData(response)})
    this.dynamicContent.OnDynamicPageChanged.subscribe({ next: (response) => this.tblPages.refreshData(response)})
    this.dynamicContent.OnDynamicMediaChanged.subscribe({ next: (response) => this.tblMedias.refreshData(response)})    
  }

}
