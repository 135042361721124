<h2 mat-dialog-title>{{'configTranslateEditorTitle' | translate}}: {{translateKey | translate}}</h2>

<mat-dialog-content>  
    
    <mat-form-field class="mx-2" [hidden]="isSaving">
        <mat-label>{{'configTranslateEditorSelectCountry' | translate }}</mat-label>
        <mat-select (selectionChange)="onChangeCountry($event)" [(ngModel)]="countrySelect">
            <mat-option *ngFor="let country of countries" [value]="country.id">{{country.name | translate}}</mat-option>
        </mat-select>
    </mat-form-field>

    <br />   

    
    <div class="row">
        <div class="col-6">
            <app-config-dynamic-form-text-editor [hidden]="isSaving" #editor></app-config-dynamic-form-text-editor>
        </div>
        <div class="col-6 preview" [innerHTML]="preview">

        </div>
    </div>

    <div class="d-flex align-items-center flex-column" *ngIf="isSaving">
        <app-loading></app-loading>
        <br />
        <span>
            {{ 'savingDataText' | translate}}
        </span>
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button [disabled]="isSaving" (click)="close()">{{ 'btn_cancel' | translate}}</button>
    <button mat-raised-button [disabled]="isSaving" color="primary" (click)="save()">{{ 'btn_save' | translate}}</button>
</mat-dialog-actions>