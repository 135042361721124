<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="card login-content shadow-lg border-0">
        <div class="text-center loginTitleLogo full-width">
          <!-- <img src="./assets/img/logoSmallWhite.png" height="140px" /> -->
          <br>
          <h3 class="text-white">Mechanic Mall</h3>
          <h3 class="text-white">- Administration -</h3>
        </div>

        <form class="card-body" [formGroup]="loginForm" (ngSubmit)="onSubmit()">   
          <h3 class="text-logo">{{ 'login_header' | translate }}</h3>
          <br>
          <div class="text-center">
              <mat-form-field class="full-width">
                  <mat-label>{{ 'login_usernameEmail' | translate }}</mat-label>
                  <input matInput formControlName="email" required>
              </mat-form-field>
            <br>
            <mat-form-field class="full-width">
                <mat-label>{{ 'login_password' | translate }}</mat-label>
                <input matInput formControlName="password" [type]="showPassword ? 'text' : 'password'" required>
                <button type="button" matSuffix mat-icon-button (click)="showPassword = !showPassword">
                  <mat-icon *ngIf="showPassword">visibility</mat-icon>
                  <mat-icon *ngIf="!showPassword">visibility_off</mat-icon>
                </button>
            </mat-form-field>
            <br>
            <button mat-raised-button color="primary" [disabled]="!loginForm.valid" type="submit" name="submit">{{ 'login_btnLogin' | translate }}</button>
            <!-- <p class="forgot"><a href="" mat-button color="primary" [routerLink]="'/forgotPwd'">{{ 'login_forgotPwd' | translate }}</a></p> -->
            <div *ngIf="accountCreated" class="textRed">{{ 'login_accountRegistered' | translate }}</div>
            <div *ngIf="unauthorized" class="textRed">{{ errorText | translate }}</div>
          </div>
        </form>
        <!-- <div class="nomember">
          <p class="text-center">{{ 'login_notAMember' | translate }} <a mat-button color="primary" [routerLink]="'/register'">{{ 'login_createAccount' | translate }}</a></p>
        </div> -->
      </div>
    </div>
  </div>
</div>