<mat-label>{{ title | translate }}</mat-label>
<div [hidden]="form.get('file').value == ''">
    <br>
    <img [src]="form.get('file').value" [width]="width" />
    <br>
    <br>
</div>
<div class="d-flex">
    <button type="button" class="my-auto" mat-raised-button (click)="fileInput1.click()">{{ 'choose_picture' | translate }}</button>
    <input hidden (change)="selectFile($event)" #fileInput1 type="file" id="file">
    <button type="button" mat-icon-button class="mx-1 removeBtn" (click)="removeFile()"><mat-icon>delete</mat-icon></button>
</div> 