import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfigTableDef } from '../config-table/config-table.component';
import { ConfigDynamicFormComponent } from '../config-dynamic-form/config-dynamic-form.component';

@Component({
  selector: 'app-config-dynamic-form-dialog',
  templateUrl: './config-dynamic-form-dialog.component.html',
  styleUrls: ['./config-dynamic-form-dialog.component.scss']
})
export class ConfigDynamicFormDialogComponent {
  tableDef: ConfigTableDef<any>
  title: string
  tblTitle: string
  closeBtn: string
  saveBtn: string

  formElement: ConfigDynamicFormComponent

  @ViewChild('formSection', { static: true, read: ViewContainerRef }) inputSection: ViewContainerRef

  constructor(
    private dialogRef: MatDialogRef<ConfigDynamicFormDialogComponent>,
  ) {}

  setData(tableDef: ConfigTableDef<any>, element: any, title: string, closeBtn: string, saveBtn: string, editData: any) {
    this.title = title
    this.tableDef = tableDef
    this.tblTitle = tableDef.showTblnameInTitle ? tableDef.tblName : ''
    this.closeBtn = closeBtn
    this.saveBtn = saveBtn

    this.formElement = this.inputSection.createComponent<ConfigDynamicFormComponent>(ConfigDynamicFormComponent).instance
    this.formElement.setData(tableDef, element, editData)
  }
  
  save() {
    var canClose = true   

    //TODO

    if(canClose) this.dialogRef.close(this.formElement.result);
  }

  close() {
    this.dialogRef.close();
  }

}
