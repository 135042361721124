import { Component, EventEmitter, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ConfigTableColType, ConfigTableDef, ConfigTableFormButton } from '../config-table/config-table.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfigDynamicFormInputComponent } from '../config-dynamic-form-input/config-dynamic-form-input.component';
import { IConfigFormElement } from '../../interface/IConfigFormElement';
import { ConfigDynamicFormDateComponent } from '../config-dynamic-form-date/config-dynamic-form-date.component';
import { ConfigDynamicFormSelectionComponent } from '../config-dynamic-form-selection/config-dynamic-form-selection.component';
import { ConfigDynamicFormTextareaComponent } from '../config-dynamic-form-textarea/config-dynamic-form-textarea.component';
import { ConfigDynamicFormButtonsComponent } from '../config-dynamic-form-buttons/config-dynamic-form-buttons.component';
import { ConfigDynamicFormSpaceComponent } from '../config-dynamic-form-space/config-dynamic-form-space.component';
import { ConfigDynamicFormRowComponent } from '../config-dynamic-form-row/config-dynamic-form-row.component';
import { ConfigDynamicFormDisplaytextComponent } from '../config-dynamic-form-displaytext/config-dynamic-form-displaytext.component';
import { ConfigDynamicFormCheckboxComponent } from '../config-dynamic-form-checkbox/config-dynamic-form-checkbox.component';
import { ConfigDynamicFormFileComponent } from '../config-dynamic-form-file/config-dynamic-form-file.component';
import { ConfigDynamicFormTextEditorComponent } from '../config-dynamic-form-text-editor/config-dynamic-form-text-editor.component';
import { ConfigDynamicFormDialogComponent } from '../config-dynamic-form-dialog/config-dynamic-form-dialog.component';
import { ConfigTranslateEditorComponent } from '../config-translate-editor/config-translate-editor.component';

export class DynamicFormResult {
  Name: string
  ValueChanged: EventEmitter<any> = new EventEmitter()

  private _value: any

  public get Value(): any {
    return this._value
  }

  public set Value(value: any) {
    this._value = value
    this.ValueChanged.emit(value)
  }

  constructor(name: string, val: any) {
    this.Name = name
    this.Value = val
  }
}

@Component({
  selector: 'app-config-dynamic-form',
  templateUrl: './config-dynamic-form.component.html',
  styleUrls: ['./config-dynamic-form.component.scss']
})
export class ConfigDynamicFormComponent {
  tableDef: ConfigTableDef<any>
  tblTitle: string

  result: DynamicFormResult[] = []

  inputToElement = new Map<string, IConfigFormElement>()
  isEdit = false

  ValueChanged: EventEmitter<any> = new EventEmitter()
  
  @ViewChild('inputSection', { static: true, read: ViewContainerRef }) inputSection: ViewContainerRef
  
  constructor(private renderer: Renderer2,
    private dialog: MatDialog,
  ){

  }

  setData(tableDef: ConfigTableDef<any>, element: any = null, editData: any = null) {
    this.tableDef = tableDef
    this.tblTitle = tableDef.tblName

    this.result = []
    this.isEdit = editData != null

    var rowDiv = null

    tableDef.cols.forEach(col => {
      if(col.isEditable) { // !this.isEdit || 
        var value = editData != null ? editData[col.name] : (element != null ? element.value : null)
        value = value != null ? value : col.defaultValue
        
        var componentElement = null

        switch(col.type){
          case ConfigTableColType.Uuid:

            break
          case ConfigTableColType.EMail:
          case ConfigTableColType.Text:
          case ConfigTableColType.Number:
          case ConfigTableColType.Password:
            componentElement = this.inputSection.createComponent<ConfigDynamicFormInputComponent>(ConfigDynamicFormInputComponent)
            break
          case ConfigTableColType.TextArea:
            componentElement = this.inputSection.createComponent<ConfigDynamicFormTextareaComponent>(ConfigDynamicFormTextareaComponent)
            break
          case ConfigTableColType.TextEditor:
            componentElement = this.inputSection.createComponent<ConfigDynamicFormTextEditorComponent>(ConfigDynamicFormTextEditorComponent)
            break
          case ConfigTableColType.Date:
            componentElement = this.inputSection.createComponent<ConfigDynamicFormDateComponent>(ConfigDynamicFormDateComponent)
            break
          case ConfigTableColType.Selection:
          case ConfigTableColType.SelectionMulti:
            componentElement = this.inputSection.createComponent<ConfigDynamicFormSelectionComponent>(ConfigDynamicFormSelectionComponent)
            break   
          case ConfigTableColType.CheckBox:
            componentElement = this.inputSection.createComponent<ConfigDynamicFormCheckboxComponent>(ConfigDynamicFormCheckboxComponent)
            break    
          case ConfigTableColType.Image:
          case ConfigTableColType.File:
            componentElement = this.inputSection.createComponent<ConfigDynamicFormFileComponent>(ConfigDynamicFormFileComponent)
            break     
          case ConfigTableColType.SPACE:   
            var spaceElement = this.inputSection.createComponent<ConfigDynamicFormSpaceComponent>(ConfigDynamicFormSpaceComponent)
            spaceElement.instance.setColData(tableDef, col)
            break   
          case ConfigTableColType.DisplayText:   
            var displayTextElement = this.inputSection.createComponent<ConfigDynamicFormDisplaytextComponent>(ConfigDynamicFormDisplaytextComponent)
            displayTextElement.instance.setValue(value)
            break
          case ConfigTableColType.TranslationEditor:   
            var translationEditorButton = this.inputSection.createComponent<ConfigDynamicFormButtonsComponent>(ConfigDynamicFormButtonsComponent)
            var buttonDefs: ConfigTableFormButton[] = [new ConfigTableFormButton({title: 'edit_' + col.name, action: () => {              
              const dialogConfig = new MatDialogConfig()
              dialogConfig.disableClose = true
              dialogConfig.autoFocus = true

              let dialogRef = this.dialog.open(ConfigTranslateEditorComponent, dialogConfig)
              dialogRef.componentInstance.setData(value)
            }})]
            translationEditorButton.instance.setButtonsDef(tableDef, buttonDefs)
            translationEditorButton.instance.margin = '5px'
            translationEditorButton.instance.addClass = 'justify-content-start'
            break
          case ConfigTableColType.CREATE_ROW:   
            rowDiv = this.inputSection.createComponent<ConfigDynamicFormRowComponent>(ConfigDynamicFormRowComponent)            
            break
          case ConfigTableColType.FINISH_ROW:   
            rowDiv = null
            break
        }

        if(componentElement != null) {
          this.inputToElement.set(col.name, componentElement.instance)
        }

        if(rowDiv != null && componentElement != null) {          
          this.renderer.addClass(componentElement.location.nativeElement, 'col-' + col.dynamicData.col)
          this.renderer.appendChild(rowDiv.location.nativeElement.firstElementChild, componentElement.location.nativeElement)
        }

        if(this.inputToElement.has(col.name)) {
          var createdElement = this.inputToElement.get(col.name)
          createdElement.setColData(tableDef, col)
          createdElement.setValue(value)

          this.result.push(new DynamicFormResult(col.name, value))
           
          createdElement.valueChange.subscribe((value) => {                
            this.result.find(r => r.Name == col.name).Value = value
            this.ValueChanged.emit()
          })
        }

      }
    })

    if(tableDef.formButtons != null && tableDef.formButtons.length > 0) {
      var buttons = this.inputSection.createComponent<ConfigDynamicFormButtonsComponent>(ConfigDynamicFormButtonsComponent)
      buttons.instance.setButtonsDef(tableDef, tableDef.formButtons)
    }

  }
  
  setValue(name: string, value: any) {
    var createdElement = this.inputToElement.get(name)
    createdElement.setValue(value)
  }
}
