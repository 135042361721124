import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfigTableColDef, ConfigTableColType, ConfigTableDef } from '../config-table/config-table.component';
import { IConfigFormElement } from '../../interface/IConfigFormElement';

@Component({
  selector: 'app-config-dynamic-form-input',
  templateUrl: './config-dynamic-form-input.component.html',
  styleUrls: ['./config-dynamic-form-input.component.scss']
})
export class ConfigDynamicFormInputComponent implements IConfigFormElement {
  label: string = ''
  hint: string = ''
  value: any
  placeholder: string = ''
  isPassword: boolean = false
  autocomplete: boolean = true
  isNumeric: boolean = false
  errorMsg: string = ''

  @Output() valueChange = new EventEmitter<any>()

  showPassword: boolean = false

  setColData(tableData: ConfigTableDef<any>, col: ConfigTableColDef) {
    this.label = tableData.getColumnTitle(col)
    
    this.isPassword = col.type == ConfigTableColType.Password
    this.isNumeric = col.type == ConfigTableColType.Number
  }

  setValue(value: any) {
    this.value = value
  }

  setErrorMessage(msg: string) {
    this.errorMsg = msg
  }

  onChangeValue(): void {
    if(this.isNumeric) this.value = this.value.replace(',', '.')
    this.valueChange.emit(this.value)
  }
}
