import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicPipeType } from '../../_pipes/dynamic.pipe';
import { DataSettingService } from '../../_services/data.settings.service';
import { RestApiService } from '../../_services/restApi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorService } from '../../_services/error.service';
import { Company } from '../../_shared/classes/Auth';
import { Setting, SettingCountry, SettingText, Translation } from '../../_shared/classes/Settings';
import { TranslateService } from '@ngx-translate/core';
import { ApiResponse, ApiResponseStatus } from '../../_shared/classes/ApiResponse';
import { Observable } from 'rxjs';
import { ConfigTableDef, ConfigTableColDef, ConfigTableColType, ConfigTableComponent } from '../../_khLib/components/config-table/config-table.component';
import { ConfigTableColSelectionType } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  
  //#region Tbl Setting
  cfgTableSetting = new ConfigTableDef<Setting>(Setting, {
    tblName: 'setting',
    showTblnameInTitle: true,
    showDetailDialog: false,
    //infoDialogTitleFunction: (e) => { return e.name},
    cols: [
      new ConfigTableColDef({name: 'id',          type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'key',         type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'valueInt',    type: ConfigTableColType.Number, showInTable: true, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'valueString', type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'valueBool',   type: ConfigTableColType.CheckBox, showInTable: true, filterable: false, pipe: DynamicPipeType.BoolToText}),   
    ], 
    showInlineEditButton: true,
    menuButtons: [

    ],   
    //createBeforeEditData: true,
    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createSetting(c),
    onCreate: (object, result) => this.dataSettings.synchSetting(),
    onCreateTextLoading: (): string => 'settingInfoCreate',
    onCreateTextLoaded: (): string => 'settingInfoCreated',
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.updateSetting(c),
    onEdit: (object, result) => this.dataSettings.synchSetting(),
    onEditTextLoading: (): string => 'settingInfoEdit',
    onEditTextLoaded: (): string => 'settingInfoEdited',

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteSetting(ids),
    onDelete: (object, result) => this.dataSettings.synchSetting(),
    onDeleteTextLoading: (): string => 'settingInfoDelete',
    onDeleteTextLoaded: (): string => 'settingInfoDeleted',

    onSynch: () => this.dataSettings.synchSetting(),

    listNameColumnFunction: (d) => d.pathName,
  })
  cfgTableSettingData = this.dataSettings.settings
  @ViewChild('tblSetting') tblSetting: ConfigTableComponent
  //#endregion

  //#region Tbl Country
  cfgTableCountry = new ConfigTableDef<SettingCountry>(SettingCountry, {
    tblName: 'country',
    showTblnameInTitle: true,
    showDetailDialog: false,
    //infoDialogTitleFunction: (e) => { return e.name},
    cols: [
      new ConfigTableColDef({name: 'id',          type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'name',        type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'shortname',   type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'taxRate',     type: ConfigTableColType.Number, showInTable: true, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'language',    type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),      
    ], 
    showInlineEditButton: true,
    menuButtons: [

    ],   
    //createBeforeEditData: true,
    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createSettingCountry(c),
    onCreate: (object, result) => this.dataSettings.synchSettingCountry(),
    onCreateTextLoading: (): string => 'countryInfoCreate',
    onCreateTextLoaded: (): string => 'countryInfoCreated',
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.updateSettingCountry(c),
    onEdit: (object, result) => this.dataSettings.synchSettingCountry(),
    onEditTextLoading: (): string => 'countryInfoEdit',
    onEditTextLoaded: (): string => 'countryInfoEdited',

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteSettingCountry(ids),
    onDelete: (object, result) => this.dataSettings.synchSettingCountry(),
    onDeleteTextLoading: (): string => 'countryInfoDelete',
    onDeleteTextLoaded: (): string => 'countryInfoDeleted',

    onSynch: () => this.dataSettings.synchSettingCountry(),

    listNameColumnFunction: (d) => d.pathName,
  })
  cfgTableCountryData = this.dataSettings.countries
  @ViewChild('tblCountry') tblCountry: ConfigTableComponent
  //#endregion

  //#region Tbl Translations
  cfgTableTranslation = new ConfigTableDef<Translation>(Translation, {
    tblName: 'translation',
    showTblnameInTitle: true,
    showDetailDialog: false,
    //infoDialogTitleFunction: (e) => { return e.name},
    cols: [
      new ConfigTableColDef({name: 'id',        type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),
      //new ConfigTableColDef({name: 'customerUuid',    type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.Customer}),        
      new ConfigTableColDef({name: 'key',    type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),        
      new ConfigTableColDef({name: 'country',    type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.Country}),        
      new ConfigTableColDef({name: 'text',    type: ConfigTableColType.TextEditor, showInTable: true, filterable: false, pipe: DynamicPipeType.LimitText}),         
      

    ], 
    showInlineEditButton: true,
    menuButtons: [

    ],   
    //createBeforeEditData: true,
    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createTranslations(c),
    onCreate: (object, result) => this.dataSettings.synchTranslations(),
    onCreateTextLoading: (): string => 'translationInfoCreate',
    onCreateTextLoaded: (): string => 'translationInfoCreated',
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.updateTranslations(c),
    onEdit: (object, result) => this.dataSettings.synchTranslations(),
    onEditTextLoading: (): string => 'translationInfoEdit',
    onEditTextLoaded: (): string => 'translationInfoEdited',

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteTranslations(ids),
    onDelete: (object, result) => this.dataSettings.synchTranslations(),
    onDeleteTextLoading: (): string => 'translationInfoDelete',
    onDeleteTextLoaded: (): string => 'translationInfoDeleted',

    onSynch: () => this.dataSettings.synchTranslations(),

    listNameColumnFunction: (d) => d.pathName,
  })
  cfgTableTranslationData = this.dataSettings.translations
  @ViewChild('tblTranslation') tblTranslation: ConfigTableComponent
  //#endregion


  constructor(private settingsData: DataSettingService,
    private restApiService: RestApiService,
    private snackbar: MatSnackBar,
    private dataSettings: DataSettingService,
    private translateService: TranslateService,
    private errorService: ErrorService) {
      
  }

  ngOnInit(): void {
    this.dataSettings.OnSettingsChanged.subscribe((response) => this.tblSetting.refreshData(response) )
    this.dataSettings.OnCountriesChanged.subscribe((response) => this.tblCountry.refreshData(response) )
    this.dataSettings.OnTranslationChanged.subscribe((response) => this.tblTranslation.refreshData(response) )
  }  







}
