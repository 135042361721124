import { Component } from '@angular/core';
import { ConfigTableColDef, ConfigTableDef } from '../config-table/config-table.component';

@Component({
  selector: 'app-config-dynamic-form-space',
  templateUrl: './config-dynamic-form-space.component.html',
  styleUrls: ['./config-dynamic-form-space.component.scss']
})
export class ConfigDynamicFormSpaceComponent {
  title: string = null
  heightBrCountBefore: number = 2
  heightBrCountAfter: number = 0

  getForArrayBeforeTitle = () => { var l=[]; for(var i=0; i<this.heightBrCountBefore;i++) {l.push(i)} return l }
  getForArrayAfterTitle = () => { var l=[]; for(var i=0; i<this.heightBrCountAfter;i++) {l.push(i)} return l }
  
  setColData(tableData: ConfigTableDef<any>, col: ConfigTableColDef) {
    this.title = col.dynamicData.title != null ? col.dynamicData.title : null
    this.heightBrCountBefore = col.dynamicData.heightBrCountBefore != null ? col.dynamicData.heightBrCountBefore : 0
    this.heightBrCountAfter = col.dynamicData.heightBrCountAfter != null ? col.dynamicData.heightBrCountAfter : 0
  }
}
