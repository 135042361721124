import { Component, OnInit, ViewChild } from '@angular/core';
import { DataDynamicContentService } from 'src/app/_services/data.dynamicContent.service';
import { DataSettingService } from 'src/app/_services/data.settings.service';
import { ErrorService } from 'src/app/_services/error.service';
import { RestApiService } from 'src/app/_services/restApi.service';
import { DynamicPage, DynamicTemplate } from 'src/app/_shared/classes/DynamicContent';
import { SettingCountry } from 'src/app/_shared/classes/Settings';
import { DesignerEditorCodeComponent } from './designer-editor-code/designer-editor-code.component';
import { ApiResponse, ApiResponseStatus } from 'src/app/_shared/classes/ApiResponse';
import { environment } from 'src/environments/environment';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-designer-editor',
  templateUrl: './designer-editor.component.html',
  styleUrl: './designer-editor.component.scss'
})
export class DesignerEditorComponent implements OnInit {

  idOffset = 1000000

  pages: DynamicPage[] = this.dynamicData.pages
  templates: DynamicTemplate[] = this.dynamicData.templates
  languages: SettingCountry[] = this.settingsData.countries
  
  pageTemplateSelect: number = null
  languageSelect: string = 'DEU'
  hasChanges: boolean = false
  isSaving: boolean = false
  saveCounter: number = 0
  
  currentHtmlCode = ''
  currentHtmlCodeRaw = ''
  currentCssCode = ''

  preview: string = ''
  error: string = ''

  @ViewChild('editorHtml', { static: true, read: DesignerEditorCodeComponent }) editorHtml: DesignerEditorCodeComponent
  @ViewChild('editorCss', { static: true, read: DesignerEditorCodeComponent }) editorCss: DesignerEditorCodeComponent

  constructor(
    private dynamicData: DataDynamicContentService,
    private settingsData: DataSettingService,
    private restApiService: RestApiService,
    private errorService: ErrorService,
    
  ) {

  }

  ngOnInit(): void {
    this.dynamicData.OnDynamicPageChanged.subscribe((pages) => {
      this.pages = pages      
      this.checkSaving()
    })
    this.dynamicData.OnDynamicTemplateChanged.subscribe((templates) => {
      this.templates = templates      
      this.checkSaving()
    })
    this.settingsData.OnCountriesChanged.subscribe((languages) => {
      this.languages = languages
    })    
    this.settingsData.OnTranslationChanged.subscribe(() => {
      this.checkSaving()
    })    

    this.editorHtml.onCodeChange.subscribe((htmlCode) => this.onChangeCodeHtml(htmlCode))
    this.editorCss.onCodeChange.subscribe((cssCode) => this.onChangeCodeCss(cssCode))
  }

  checkSaving() {    
    if(this.saveCounter > 0) {
      if(--this.saveCounter == 0) {
        this.isSaving = false
        this.hasChanges = false        
      }
    }
  }

  loadEditor() {    
    if(this.pageTemplateSelect == null || this.languageSelect == null) return

    var country = this.settingsData.countries.find(c => c.id == this.languageSelect)
    if(country == null){
      this.error = 'Cant find country!'
      return
    }

    if(this.pageTemplateSelect < this.idOffset) {
      var page = this.dynamicData.pages.find(p => p.id == this.pageTemplateSelect)
  
      if(page == null){
        this.error = 'Cant find page!'
        return
      }
  
      var translation = this.settingsData.translations.find(t => t.key == page.contentLang && t.country == country.id)
      var content = ''  
      if(translation != null) content = translation.text
  
      this.editorHtml.setCode(content)
      this.editorCss.setCode(page.cssStyle)        
      this.hasChanges = false
    } else {
      var template = this.dynamicData.templates.find(p => p.id == (this.pageTemplateSelect - this.idOffset))
  
      if(template == null){
        this.error = 'Cant find template!'
        return
      }
  
      var translation = this.settingsData.translations.find(t => t.key == template.contentLang && t.country == country.id)
      var content = ''  
      if(translation != null) content = translation.text
  
      this.editorHtml.setCode(content)
      this.editorCss.setCode(template.cssStyle)        
      this.hasChanges = false
    }

    
    this.onSetPreview()
  }

  onChangeCodeHtml(htmlCode: string) {
    this.hasChanges = true
    this.setHtmlCode(htmlCode)
    this.onSetPreview()
  }

  onChangeCodeCss(cssCode: string) {
    this.hasChanges = true
    this.currentCssCode = cssCode
    this.onSetPreview()        
  }

  onSetPreview() {
    var element = document.querySelectorAll('[prev="true"]');
    if(element != null && element.length > 0) {  
      element[0].removeChild(element[0].firstChild)
      element[0].appendChild(document.createTextNode(this.currentCssCode));

    } else {
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.setAttribute('prev', 'true')  
      style.appendChild(document.createTextNode(this.currentCssCode));
      head.appendChild(style);
    }     
  }

  onClickSave() {
    var data = {
      html: this.currentHtmlCodeRaw,
      css: this.currentCssCode
    }
    this.isSaving = true
    
    if(this.pageTemplateSelect < this.idOffset) {
      this.restApiService.setDynamicPageHtmlCss(this.pageTemplateSelect, data).subscribe({
          next: (response: ApiResponse) => {
              if(response.status == ApiResponseStatus.Success) {
                this.saveCounter = 3              
                this.dynamicData.synchDynamicPage()
                this.dynamicData.synchDynamicTemplate()
                this.settingsData.synchTranslations()
              } else {
                this.error = response.message
              }
          },
          error: (error) => this.errorService.OnError.error(error)
      })
    } else {
      this.restApiService.setDynamicTemplateHtmlCss(this.pageTemplateSelect - this.idOffset, data).subscribe({
          next: (response: ApiResponse) => {
              if(response.status == ApiResponseStatus.Success) {
                this.saveCounter = 3              
                this.dynamicData.synchDynamicPage()
                this.dynamicData.synchDynamicTemplate()
                this.settingsData.synchTranslations()
              } else {
                this.error = response.message
              }
          },
          error: (error) => this.errorService.OnError.error(error)
      })
    }
  }

  setHtmlCode(htmlCode: string) {
    this.currentHtmlCodeRaw = htmlCode
      while(true) {
          var s = htmlCode.indexOf('[media=\'')
          if(s == -1) break
          
          var e = htmlCode.indexOf('\']', s+1)
    
          var mediaStr = htmlCode.substring(s, e+2)
          var mediaName = htmlCode.substring(s+8, e)
          var media = this.dynamicData.medias.find(m => m.name == mediaName)

          var replaceUrl = ''
          if(media != null) {
              replaceUrl = environment.apiPath + '/' + media.url
          }

          htmlCode = htmlCode.replace(mediaStr, replaceUrl)          
      }
      this.currentHtmlCode = htmlCode
  }

}
