import { EventEmitter, Injectable } from "@angular/core";


@Injectable()
export class LanguageLoaderService {
    public onChangeLanguage: EventEmitter<void> = new EventEmitter()




}