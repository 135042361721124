import { Component } from '@angular/core';
import { ConfigTableDef, ConfigTableFormButton } from '../config-table/config-table.component';

@Component({
  selector: 'app-config-dynamic-form-buttons',
  templateUrl: './config-dynamic-form-buttons.component.html',
  styleUrls: ['./config-dynamic-form-buttons.component.scss']
})
export class ConfigDynamicFormButtonsComponent {
  buttons: ConfigTableFormButton[]

  margin = '0px'
  addClass = 'justify-content-end'

  setButtonsDef(tableData: ConfigTableDef<any>, buttons: ConfigTableFormButton[]){
    buttons.forEach(btn => btn.title = tableData.tblName + '_' + btn.title)
    this.buttons = buttons
  }
}
