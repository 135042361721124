import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dynamic-dialog',
  templateUrl: './dynamic-dialog.component.html',
  styleUrls: ['./dynamic-dialog.component.scss']
})
export class DynamicDialogComponent {
  @Input() title: string
  @Input() title2: string
  @Input() text: string
  @Input() listItems: string[] = []
  @Input() btnTextYes: string = 'btn_yes'
  @Input() btnTextNo: string = 'btn_no'

  constructor(
    private dialogRef: MatDialogRef<DynamicDialogComponent>
  ) { }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
