


<div class="d-flex flex-row align-items-center">

    <mat-form-field class="mx-3">
      <mat-label>{{ 'dynamicEditorPage' | translate }}</mat-label>
      <mat-select [(ngModel)]="pageTemplateSelect" (selectionChange)="loadEditor()">        
        <mat-optgroup label="Seiten">
          <mat-option *ngFor="let page of pages" [value]="page.id">{{page.name}}</mat-option>
        </mat-optgroup>
        <mat-optgroup label="Templates">
          <mat-option *ngFor="let template of templates" [value]="template.id + idOffset">{{template.name}}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mx-3">
      <mat-label>{{ 'dynamicEditorLanguage' | translate }}</mat-label>
      <mat-select [(ngModel)]="languageSelect" (selectionChange)="loadEditor()">
        <mat-option *ngFor="let language of languages" [value]="language.id">{{language.name}}</mat-option>    
      </mat-select>
    </mat-form-field>

    <button class="saveBtn mx-3" mat-raised-button color="primary" [disabled]="!hasChanges || isSaving" (click)="onClickSave()">
        <div class="d-flex">
          <mat-spinner *ngIf="isSaving" class="saveSpinner"></mat-spinner>
            <span class="ms-2">{{ 'btn_save' | translate }}</span>
        </div>
    </button>

</div>

<div class="row">    
    <div class="col-6">
        <span *ngIf="pageTemplateSelect == null || languageSelect == null">{{ 'dynamicEditorSetSettings' | translate }}</span>
        <span *ngIf="error != ''">{{error}}</span>
        <mat-tab-group [hidden]="pageTemplateSelect == null || languageSelect == null || error != ''">
            <mat-tab label="HTML">
                <app-designer-editor-code #editorHtml [language]="'html'"></app-designer-editor-code>            
            </mat-tab>
            <mat-tab label="CSS">
                <app-designer-editor-code #editorCss [language]="'css'"></app-designer-editor-code>    
            </mat-tab>
        </mat-tab-group>   
    </div>
    <div class="col-6 preview" [innerHTML]="currentHtmlCode">
        
    </div>   
</div>


