import { Component } from '@angular/core';
import { ConfigTableDef } from '../config-table/config-table.component';

@Component({
  selector: 'app-config-table-info-dialog',
  templateUrl: './config-table-info-dialog.component.html',
  styleUrls: ['./config-table-info-dialog.component.scss']
})
export class ConfigTableInfoDialogComponent {
  tableDef: ConfigTableDef<any>
  data: any
  
  setData(tableDef: ConfigTableDef<any>, data) {
    this.tableDef = tableDef
    this.data = data
  }

  getDisplayCols(){
    return this.tableDef.cols.filter(c => c.name != 'actions')
  }
  
  combinePipeArgs(args1: any[], arg2: any) {
    if(args1 != null && arg2 != null) return [... args1, arg2]
    if(args1 != null) return [... args1]
    if(arg2 != null) return [arg2]
    return null    
  }
}
