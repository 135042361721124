import { EventEmitter, Injectable } from "@angular/core";
import { RestApiService } from "./restApi.service";
import { DataParentService } from "./_dataParent.service";
import { Router } from "@angular/router";
import { ApiResponse, ApiResponseStatus } from "../_shared/classes/ApiResponse";
import { JwtService } from "./jwt.service";
import { CookieService } from "ngx-cookie-service";
import { Project, Service, Setting, SettingCountry, SettingText, Translation } from "../_shared/classes/Settings";

@Injectable()
export class DataSettingService extends DataParentService  {
    //USER DATA
    public countries: SettingCountry[] = []
    public texts: SettingText[] = []
    public settings: Setting[] = []
    public translations: Translation[] = []

    public projects: Project[] = []
    public services: Service[] = []

    public OnCountriesChanged : EventEmitter<SettingCountry[]> = new EventEmitter()
    public OnTextChanged : EventEmitter<SettingText[]> = new EventEmitter()
    public OnSettingsChanged : EventEmitter<Setting[]> = new EventEmitter()
    public OnTranslationChanged : EventEmitter<Translation[]> = new EventEmitter()

    public OnProjectChanged : EventEmitter<Project[]> = new EventEmitter()
    public OnServiceChanged : EventEmitter<Service[]> = new EventEmitter()
        
    constructor(private restApiService: RestApiService,
        private jwtService: JwtService,
        private cookieService: CookieService,
        private router: Router)
    {                
        super(6)
    }  

    public loadData(){
        this.synchSettingCountry()
        this.synchSettingText()
        this.synchSetting()        
        this.synchTranslations()
        this.synchProject()
        this.synchService()
    }

    public loadedFinish() {        
        
    }

    public synchSettingCountry() {
        this.restApiService.getSettingCountryList().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.countries = response.value as SettingCountry[]
                    this.OnCountriesChanged.emit(this.countries)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);
                
                this.OnCountriesChanged.error(error)
            }
        })
    }  

    public synchSettingText() {
        this.restApiService.getSettingTextList().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.texts = response.value as SettingText[]
                    this.OnTextChanged.emit(this.texts)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);
                
                this.OnTextChanged.error(error)
            }
        })
    }

    public synchSetting() {
        this.restApiService.getSettingList().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.settings = response.value as Setting[]
                    this.OnSettingsChanged.emit(this.settings)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);
                
                this.OnSettingsChanged.error(error)
            }
        })
    }
    
    public synchTranslations() {
        this.restApiService.getTranslations().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.translations = response.value as Translation[]
                    this.OnTranslationChanged.emit(this.translations)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);                
                this.OnTranslationChanged.error(error)
            }
        })
    }
    
    public synchProject() {
        this.restApiService.getProjects().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.projects = response.value as Project[]
                    this.OnProjectChanged.emit(this.projects)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);                
                this.OnProjectChanged.error(error)
            }
        })
    }
    
    public synchService() {
        this.restApiService.getServices().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.services = response.value as Service[]
                    this.OnServiceChanged.emit(this.services)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);                
                this.OnServiceChanged.error(error)
            }
        })
    }

    
}