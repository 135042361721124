import {
    Injectable,
    Injector,
    Pipe,
    PipeTransform
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DataUserManagementService } from '../_services/data.usermanagement.service';
import { DataSettingService } from '../_services/data.settings.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { DataDynamicContentService } from '../_services/data.dynamicContent.service';

export enum DynamicPipeType {
    None = 0,
    BoolToText = 1,
    Euro = 2,     
    Date = 3,
    Percentage = 4,   
    Image = 5,
    ColorBox = 6,  
    Icon = 7,  
    LimitText = 8,  

    Country = 11,   

    AccountType = 21,    
    Customer = 22,   
    Menu = 25,   
    MenuEntries = 26,   
    Page = 27,   



}

@Pipe({
  name: 'dynamicPipe'
})
@Injectable()
export class DynamicPipe implements PipeTransform {

    public constructor(
        private injector: Injector,
        private tranlastePipe: TranslatePipe,
        private settingsData: DataSettingService,
        private dataDynamicContent: DataDynamicContentService,
        private authData: DataUserManagementService,
        private _sanitizer: DomSanitizer) {
    }

    transform(value: any, pipeType: DynamicPipeType, pipeArgs: any[]): any {
        if(pipeType == null) pipeType = DynamicPipeType.None
        switch(pipeType) {
            case DynamicPipeType.None:
                return value
            case DynamicPipeType.BoolToText:             
                if(value == null) return ""                
                return JSON.parse(value) ? this.tranlastePipe.transform('bool_yes') : this.tranlastePipe.transform('bool_no')
            case DynamicPipeType.Euro: 
                if(value == null || value === '') return '-'    
                var euro = parseFloat(value)
                return euro.toFixed(2) + ' €'            
            case DynamicPipeType.Customer:  
                var customer = this.authData.customer.find(c => c.uuid == value)
                if(customer != null)
                {
                    value = customer.firstname + ' ' + customer.name
                }
                return value    
            case DynamicPipeType.Date:   
                if(value == null) return '-'             
                var date = new Date(value);
                return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth()+1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
            case DynamicPipeType.Percentage:
                return value + "%"
            case DynamicPipeType.Country:                
                var country = this.settingsData.countries.find(c => c.id == value)
                return country != null ? country.name : '-'
            case DynamicPipeType.Image:
                return value != null && value != '' ? `<img src="${environment.apiPath + '/' + value}" width="100px" />` : ''
            case DynamicPipeType.ColorBox:
                return this._sanitizer.bypassSecurityTrustHtml(`<div style="background-color: ${value}; height: 30px; width: 30px;"></div>`) 
            case DynamicPipeType.AccountType:              
                if(value == 0) return this.tranlastePipe.transform('customers_accountType_private')                
                if(value == 1) return this.tranlastePipe.transform('customers_accountType_company')           
                if(value == 2) return this.tranlastePipe.transform('customers_accountType_seller')                     
                return ""
            case DynamicPipeType.Icon:
                return this._sanitizer.bypassSecurityTrustHtml(`<mat-icon>${value}</mat-icon>`) 
            case DynamicPipeType.Menu:
                var menu = this.dataDynamicContent.menues.find(c => c.id == value)
                return menu != null ? menu.name : '-'
            case DynamicPipeType.MenuEntries:
                var menuEntry = this.dataDynamicContent.menuEntries.find(c => c.id == value)
                return menuEntry != null ? menuEntry.pathName : '-'
            case DynamicPipeType.Page:
                var page = this.dataDynamicContent.pages.find(c => c.id == value)
                return page != null ? page.name : '-'
            case DynamicPipeType.LimitText:
                if(value != null && value.toString().length > 53) {
                    return value.substring(0,50) + '...'
                } 
                return value
        }
        

    }
}