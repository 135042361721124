import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataUserManagementService } from 'src/app/_services/data.usermanagement.service';

@Component({
  selector: 'app-auth-logout',
  template: ''
})
export class AuthLogoutComponent {
  constructor(private dataUserManagement: DataUserManagementService, private router: Router) {
    dataUserManagement.logoutUser()
    this.router.navigate(['auth/login'])
  }  
}
