<div>
    <a mat-list-item [routerLink]="menu.routerLink" routerLinkActive="{{menu.routerLinkActive}}" *ngIf="menu.subMenu.length == 0" [routerLinkActiveOptions]="{exact: true}" autosize>
        <mat-icon mat-list-icon class="icon" *ngIf="menu.icon != ''">{{menu.icon}}</mat-icon>
        <span class="full-width mx-2" *ngIf="isExpanded">{{menu.name | translate}}</span>
        <span class="mx-1"></span>
    </a>
    
    <mat-list-item (click)="showSubMenu = !showSubMenu" class="parent" *ngIf="menu.subMenu.length > 0">
        <mat-icon mat-list-icon class="icon">{{menu.icon}}</mat-icon>
        <span class="full-width mx-2 px-2" *ngIf="isExpanded">{{menu.name | translate}}</span>
        <mat-icon class="menu-button mx-4" *ngIf="isExpanded" [ngClass]="{'rotated' : showSubMenu}">expand_more</mat-icon>
    </mat-list-item>
    <div class="submenu ms-3" [ngClass]="{'expanded' : showSubMenu}" *ngIf="(showSubMenu) && menu.subMenu.length > 0">
        <app-sidemenu-button *ngFor="let m of menu.subMenu" [menu]="m" [isExpanded]="isExpanded"></app-sidemenu-button>
    </div>
</div>
