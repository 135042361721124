import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IConfigFormElement } from '../../interface/IConfigFormElement';
import { ConfigTableDef, ConfigTableColDef } from '../config-table/config-table.component';

@Component({
  selector: 'app-config-dynamic-form-text-editor',
  templateUrl: './config-dynamic-form-text-editor.component.html',
  styleUrls: ['./config-dynamic-form-text-editor.component.scss']
})
export class ConfigDynamicFormTextEditorComponent implements IConfigFormElement {
  title: string = ''
  _rawText = ''

  public get rawText() {
    return this._rawText
  }

  public set rawText(rt: string) {
    this._rawText = rt
    this.onChangeValue()
  }
  
  @Output() valueChange = new EventEmitter<string>() 

  
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '300px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'Cantarell', name: 'Cantarell'}
    ],
    customClasses: [],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertImage', 'insertVideo'],
    ]
  }

  constructor(private _formBuilder: FormBuilder) {

  }
  setColData(tableData: ConfigTableDef<any>, col: ConfigTableColDef): void {
    this.title = tableData.getColumnTitle(col)    
  }
  setErrorMessage(msg: string): void {
    
  }
  onChangeValue(): void {
    this.valueChange.emit(this.rawText)    
  }
  
  setValue(rawText: string) {
    this.rawText = rawText
  }
  
}
