import { APP_INITIALIZER, ApplicationRef, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Angular Materials */
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatCommonModule, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SidemenuButtonComponent } from './components/sidemenu-button/sidemenu-button.component';

import { SettingsComponent } from './public/settings/settings.component';
import { InfoComponent } from './public/info/info.component';
import { DashboardComponent } from './public/dashboard/dashboard.component'

import { HttpClient, HttpClientModule } from '@angular/common/http';
import {TranslateModule, TranslateLoader, TranslatePipe} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CfgCardComponent } from './components/cfg-card/cfg-card.component';
import { RestApiService } from './_services/restApi.service';
import { ErrorBoxDirective } from './_directives/error.directive';
import { LanguageLoaderService } from './_services/languageLoader.service';
import { DisableControlDirective } from './_directives/disableControl.directive';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DynamicPipe, DynamicPipeType } from './_pipes/dynamic.pipe';
import { YesNoDialogComponent } from './components/yes-no-dialog/yes-no-dialog.component';
import { StaticFunctions } from './_services/static.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { DataUserManagementService } from './_services/data.usermanagement.service';
import { AuthLoginComponent } from './authentification/auth-login/auth-login.component';
import { JwtService } from './_services/jwt.service';
import { CookieService } from 'ngx-cookie-service';
import { CustomTranslateService } from './_services/customtranslate.service';
import { AuthLogoutComponent } from './authentification/auth-logout/auth-logout.component';
import { ErrorService } from './_services/error.service';
import { CustomerComponent } from './public/customer/customer.component';
import { NgxMatTimepickerModule, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { DataSettingService } from './_services/data.settings.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ConfigTableComponent } from './_khLib/components/config-table/config-table.component';
import { ConfigTableInfoDialogComponent } from './_khLib/components/config-table-info-dialog/config-table-info-dialog.component';
import { ConfigDynamicFormComponent } from './_khLib/components/config-dynamic-form/config-dynamic-form.component';
import { ConfigDynamicFormInputComponent } from './_khLib/components/config-dynamic-form-input/config-dynamic-form-input.component';
import { ConfigDynamicFormDateComponent } from './_khLib/components/config-dynamic-form-date/config-dynamic-form-date.component';
import { ConfigDynamicFormSelectionComponent } from './_khLib/components/config-dynamic-form-selection/config-dynamic-form-selection.component';
import { HelperService } from './_services/helper.service';
import { DynamicDialogComponent } from './_khLib/components/dynamic-dialog/dynamic-dialog.component';
import { ConfigDynamicFormButtonsComponent } from './_khLib/components/config-dynamic-form-buttons/config-dynamic-form-buttons.component';
import { ConfigDynamicFormCheckboxComponent } from './_khLib/components/config-dynamic-form-checkbox/config-dynamic-form-checkbox.component';
import { ConfigDynamicFormDialogComponent } from './_khLib/components/config-dynamic-form-dialog/config-dynamic-form-dialog.component';
import { ConfigDynamicFormDisplaytextComponent } from './_khLib/components/config-dynamic-form-displaytext/config-dynamic-form-displaytext.component';
import { ConfigDynamicFormFileComponent } from './_khLib/components/config-dynamic-form-file/config-dynamic-form-file.component';
import { ConfigDynamicFormSpaceComponent } from './_khLib/components/config-dynamic-form-space/config-dynamic-form-space.component';
import { ConfigDynamicFormTextareaComponent } from './_khLib/components/config-dynamic-form-textarea/config-dynamic-form-textarea.component';
import { ConfigMedialistComponent } from './_khLib/components/config-medialist/config-medialist.component';
import { ConfigDynamicFormTextEditorComponent } from './_khLib/components/config-dynamic-form-text-editor/config-dynamic-form-text-editor.component';
import { ConfigTranslateEditorComponent } from './_khLib/components/config-translate-editor/config-translate-editor.component';
import { LoadingComponent } from './components/loading/loading.component';
import { DesignerComponent } from './public/designer/designer.component';
import { DataDynamicContentService } from './_services/data.dynamicContent.service';
import { PageDesignerSettingsComponent } from './public/designer/page-designer-settings/page-designer-settings.component';
import { CodeEditorModule } from '@ngstack/code-editor';
import { DesignerEditorCodeComponent } from './public/designer-editor/designer-editor-code/designer-editor-code.component';
import { DesignerEditorComponent } from './public/designer-editor/designer-editor.component';
import { ProjectsComponent } from './public/projects/projects.component';
import { ServicesComponent } from './public/services/services.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    SidemenuButtonComponent,
    SettingsComponent,
    InfoComponent,
    DashboardComponent,
    CfgCardComponent,
    ErrorBoxDirective,
    DisableControlDirective,
    DynamicPipe,
    YesNoDialogComponent,

    AuthLoginComponent,
    AuthLogoutComponent,
    CustomerComponent,
    
    ConfigTableComponent,
    ConfigTableInfoDialogComponent,
    ConfigDynamicFormComponent,
    ConfigDynamicFormDialogComponent,
    ConfigDynamicFormInputComponent,
    ConfigDynamicFormDateComponent,
    ConfigDynamicFormSelectionComponent,
    ConfigDynamicFormButtonsComponent,
    ConfigDynamicFormCheckboxComponent,
    ConfigDynamicFormDateComponent,
    ConfigDynamicFormDisplaytextComponent,
    ConfigDynamicFormSpaceComponent,
    ConfigDynamicFormTextareaComponent,
    DynamicDialogComponent,
    ConfigDynamicFormFileComponent,
    ConfigMedialistComponent,
    ConfigDynamicFormTextEditorComponent,
    ConfigTranslateEditorComponent,
    LoadingComponent,
    DesignerComponent,
    PageDesignerSettingsComponent,
    DesignerEditorCodeComponent,
    DesignerEditorComponent,
    ProjectsComponent,
    ServicesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    /* Angular Materials */
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatCommonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatNativeDateModule,
    DragDropModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatNativeDateModule,
    ColorPickerModule,
    AngularEditorModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    CodeEditorModule.forRoot({
      baseUrl: 'assets/monaco',
      typingsWorkerUrl: 'assets/workers/typings-worker.js'
    }),
], 
  exports: [
    TranslateModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatNativeDateModule,
  ],
  providers: [
    RestApiService,
    LanguageLoaderService,
    TranslatePipe,
    StaticFunctions,
    DataUserManagementService,
    DataSettingService,
    JwtService,
    CookieService,
    CustomTranslateService,
    ErrorService,
    DynamicPipe,
    HelperService,
    DataDynamicContentService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
