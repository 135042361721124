import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfigTableDef } from '../config-table/config-table.component';
import { ApiResponse, ApiResponseStatus } from 'src/app/_shared/classes/ApiResponse';
import { environment } from 'src/environments/environment';
import { RestApiService } from 'src/app/_services/restApi.service';

class MediaListEntry {
  fileName: string
  widthHeight: string
  picture: any

  public constructor(init?:Partial<MediaListEntry>) {
      Object.assign(this, init);
  }
}

@Component({
  selector: 'app-config-medialist',
  templateUrl: './config-medialist.component.html',
  styleUrls: ['./config-medialist.component.scss']
})
export class ConfigMedialistComponent {
  @Input() title: string
  @Input() title2: string

  tableData: ConfigTableDef<any> = null
  element: any = null
  mediaListEntries: MediaListEntry[] = []

  constructor(
    private dialogRef: MatDialogRef<ConfigMedialistComponent>,
    private restApiService: RestApiService
  ) { }

  setData(tableData: ConfigTableDef<any>, element, value: string) {
    this.tableData = tableData
    this.element = element
          
    if(value != null && value.length > 0) {
      var images = value.split(';')
      images.forEach(image => {
        var imageData = image.split('|')
        
        this.mediaListEntries.push(new MediaListEntry({
          fileName: imageData[0],
          widthHeight: imageData[1],
          picture: environment.apiPath + '/' + imageData[2]
        })) 
      })
    }    
  }

  onClickClose() {
    this.dialogRef.close();
  }
  
  uploadFiles: File[] = []
  selectFiles(event) {    
    Array.from(event.target.files).forEach(f => this.uploadFiles.push(f as File))    
    this.uploadSingleFile()
  }

  uploadSingleFile() {
    var file = this.uploadFiles[0]
    
    var formData: FormData = new FormData()
    formData.append('file', file) 
    
    const reader = new FileReader()
    reader.onload = (e: any) => {
      this.mediaListEntries.push(new MediaListEntry({
        fileName: file.name,
        picture: e.target.result
      })) 
      
      this.tableData.uploadImage(this.element.uuid || this.element.id, formData).subscribe({
          next: (response: ApiResponse) => {
              if(response.status == ApiResponseStatus.Success) {         
                this.uploadFiles.shift()       
                if(this.uploadFiles.length > 0) {
                  this.uploadSingleFile()
                } else {                
                  if(this.tableData.onSynch != null) this.tableData.onSynch()
                }
              }
          },
      })
    }
    reader.readAsDataURL(file)
  }

  downloadFile(mediaEntry: MediaListEntry) {
    this.restApiService.downloadImage(mediaEntry.picture).subscribe({
        next: (response) => {          
          var url = window.URL.createObjectURL(response)
          var anchor = document.createElement("a")
          anchor.download = mediaEntry.fileName
          anchor.href = url
          anchor.click()                
        },
    })      
  }

  removeFile(mediaEntry: MediaListEntry) {       
    this.tableData.deleteImage(this.element.uuid || this.element.id, mediaEntry.fileName).subscribe({
      next: (response: ApiResponse) => {
          if(response.status == ApiResponseStatus.Success) {
            const index = this.mediaListEntries.indexOf(mediaEntry, 0)
            if (index > -1) {
              this.mediaListEntries.splice(index, 1)
            }
            if(this.tableData.onSynch != null) this.tableData.onSynch()
          }
      },
    })  
  }

  fileMoveUp(mediaEntry: MediaListEntry) {
    this.tableData.sortImage(this.element.uuid || this.element.id, 1, mediaEntry.fileName).subscribe({
        next: (response: ApiResponse) => {
            if(response.status == ApiResponseStatus.Success) {
              this.moveInDialog(mediaEntry.fileName, 1)
              if(this.tableData.onSynch != null) this.tableData.onSynch()
            }
        },
    })
  }

  fileMoveDown(mediaEntry: MediaListEntry) {
    this.tableData.sortImage(this.element.uuid || this.element.id, 2, mediaEntry.fileName).subscribe({
        next: (response: ApiResponse) => {
            if(response.status == ApiResponseStatus.Success) {
              this.moveInDialog(mediaEntry.fileName, 2)
              if(this.tableData.onSynch != null) this.tableData.onSynch()
            }
        },
    })
  }

  moveInDialog(fileName: string, sortMode: number){
    var foundPosition = -1
    var counter = 0
    this.mediaListEntries.forEach(i => {
        if(foundPosition == -1) {
            if(i.fileName == fileName) {
                foundPosition = counter
            }
        }
        counter++
    })
    var targetPosition = sortMode == 1 ? foundPosition - 1 : foundPosition + 1
    
    if(targetPosition >= 0) {
      this.mediaListEntries.splice(targetPosition, 0, this.mediaListEntries.splice(foundPosition, 1)[0])   
    }  
  }

}
