
<div [hidden]="isLoadingFinish() && (errorShow$ | async) == ''">
    <div class="loadingDiv">
        <div class="loadingTitle">{{ 'navigation_header' | translate }}</div>
        <div>
            <img src="./assets/img/Logo.png" height="160px" />
        </div>
        <!--<mat-spinner></mat-spinner>
        <br>
        <br>
        {{ 'loadingText' | translate }} -->
        <br><br>
        <div [hidden]="isLoaded">
            <div class="mt-1 loadingLine">
                <mat-icon [hidden]="loadingAuth != 0">hourglass_empty</mat-icon>
                <div [hidden]="loadingAuth != 1" class="spinner-grow text-light loadingSmall"> </div>
                <mat-icon [hidden]="loadingAuth != 2">done</mat-icon>
                <mat-icon [hidden]="loadingAuth != 3">close</mat-icon>
                <span class="ms-4" style="width: 200px; text-align: left;">{{ 'loading_auth' | translate }}</span>
            </div>
            <div class="mt-1 loadingLine">
                <mat-icon [hidden]="loadingDataUsermanagement != 0">hourglass_empty</mat-icon>
                <div [hidden]="loadingDataUsermanagement != 1" class="spinner-grow text-light loadingSmall"> </div>
                <mat-icon [hidden]="loadingDataUsermanagement != 2">done</mat-icon>
                <mat-icon [hidden]="loadingDataUsermanagement != 3">close</mat-icon>
                <span class="ms-4" style="width: 200px; text-align: left;">{{ 'loading_dataUsermanagement' | translate }}</span>
            </div>
            <div class="mt-1 loadingLine">
                <mat-icon [hidden]="loadingSettings != 0">hourglass_empty</mat-icon>
                <div [hidden]="loadingSettings != 1" class="spinner-grow text-light loadingSmall"> </div>
                <mat-icon [hidden]="loadingSettings != 2">done</mat-icon>
                <mat-icon [hidden]="loadingSettings != 3">close</mat-icon>
                <span class="ms-4" style="width: 200px; text-align: left;">{{ 'loading_settings' | translate }}</span>
            </div>
            <div class="mt-1 loadingLine">
                <mat-icon [hidden]="loadingDynamicContent != 0">hourglass_empty</mat-icon>
                <div [hidden]="loadingDynamicContent != 1" class="spinner-grow text-light loadingSmall"> </div>
                <mat-icon [hidden]="loadingDynamicContent != 2">done</mat-icon>
                <mat-icon [hidden]="loadingDynamicContent != 3">close</mat-icon>
                <span class="ms-4" style="width: 200px; text-align: left;">{{ 'loading_settings' | translate }}</span>
            </div>
        </div>
        <br>
        <div [innerHTML]="errorShow$ | async"></div>
    </div>
    <!--<div class="loadingDiv" [innerHTML]="error"></div>-->
</div>

<div [hidden]="!isLoadingFinish() || (errorShow$ | async) != ''" class="mat-app-background content-wrapper">
    <app-navigation *ngIf="loggedIn$ | async">
        <router-outlet></router-outlet>        
    </app-navigation>  
    
    <router-outlet *ngIf="!(loggedIn$ | async)"></router-outlet>  
</div>
