
<div>    
  <br>
  <button mat-raised-button color="primary" class="me-1" *ngIf="tableDefinition?.canAdd" (click)="onClickAdd()"><mat-icon>add</mat-icon>{{ 'configuration_general_btn_newEntry' | translate}}</button>
  <button mat-raised-button color="primary" class="me-1" *ngIf="selectedRowIds.size == 1 && tableDefinition?.canEditDelete" (click)="onClickEdit()"><mat-icon>edit</mat-icon>{{ 'configuration_general_btn_edit' | translate}}</button>
  <button mat-raised-button color="primary" class="me-1" *ngIf="selectedRowIds.size == 1 && tableDefinition?.canEditDelete" (click)="onClickCopy()"><mat-icon>content_copy</mat-icon>{{ 'configuration_general_btn_copy' | translate}}</button>

  <button mat-raised-button color="primary" class="me-1" *ngFor="let i of tableDefinition.actions" (click)="i.action()"><mat-icon [innerText]="i.btnIcon"></mat-icon>{{ i.btnText | translate}}</button>
  <div *ngIf="selectedRowIds.size > 0" style="display: contents;">
    <button mat-raised-button color="primary" class="me-1" *ngFor="let i of tableDefinition.actionsMultipleElement" (click)="i.action(selectedRowIds[0])"><mat-icon [innerText]="i.btnIcon"></mat-icon>{{ i.btnText | translate}}</button>
  </div>
  
  <button mat-raised-button color="warn" class="me-1" *ngIf="selectedRowIds.size == 1 &&  tableDefinition?.canEditDelete" (click)="onDelete(false)"><mat-icon>delete</mat-icon>{{ 'configuration_general_btn_delete' | translate}}</button>
  
  <button mat-raised-button color="warn" class="me-1" *ngIf="selectedRowIds.size > 1 &&  tableDefinition?.canEditDelete" (click)="onDelete(false)"><mat-icon>delete</mat-icon>{{ 'configuration_general_btn_deleteMany' | translate}}</button>
  <button mat-raised-button color="warn" class="me-1" *ngIf="dataSource.data.length > 0 &&  tableDefinition?.canDeleteAll" (click)="onDelete(true)"><mat-icon>delete</mat-icon>{{ 'configuration_general_btn_deleteAll' | translate}}</button>
</div>
<br>

<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" (matSortChange)="sortData($event)">
    
  <ng-container *ngFor="let col of tableDefinition.cols; let i = index">
    <ng-container matColumnDef="{{col.name}}">

      <th *matHeaderCellDef mat-header-cell>
        <div *ngIf="col.type != colType.None" [mat-sort-header]="col.sortable ? col.name : null" [disabled]="!col.sortable">
          {{ tableDefinition.getColumnTitle(col) | translate }}
        </div>
        <div *ngIf="col.filterable" [formGroup]="filterForm">
          <mat-form-field style="margin-bottom: -1.25em;" [ngStyle]="{'width': col.filterWidth}">
            <mat-select *ngIf="col.type == colType.Selection" [formControlName]="col.name" (selectionChange)="filterData()">
              <input *ngIf="dropDownInitValues[col.selectionType].length > 5" class="selectSearchInput" (keyup)="onSearchInFilter($event.target, col.name)">
              <mat-option *ngFor="let dd of dropDownValues[col.name]" [value]="dd.key">{{ dd.value| translate }}</mat-option>
            </mat-select>

            <input *ngIf="col.type == colType.Text || col.type == colType.Number || col.type == colType.Uuid || col.type == colType.EMail" matInput formControlName="{{col.name}}" (ngModelChange)="filterData()">
          </mat-form-field>
        </div>
      </th>

      <td mat-cell *matCellDef="let element; let colI = index">
        <div class="d-flex">
          <div class="d-flex align-items-center" *ngIf="col.buttons">
            <div *ngFor="let btn of col.buttons; let j = index">
              <button mat-raised-button *ngIf="!btn.isFab" color="primary" class="me-1" (click)="btn.action($event, element)"><mat-icon [innerText]="btn.btnIcon"></mat-icon>{{ btn.btnText | translate}}</button>
              <button mat-mini-fab *ngIf="btn.isFab" color="primary" class="me-1" (click)="btn.action($event, element)"><mat-icon [innerText]="btn.btnIcon"></mat-icon></button>
            </div>
          </div>  

          <div *ngIf="col.name == 'actions'" [ngStyle]="{'min-width': tableDefinition.showDetailDialog && tableDefinition.menuButtons != null && tableDefinition.menuButtons.length > 0 ? '60px' : '30px'}">
            <div class="d-flex flex-row">
              <button *ngIf="tableDefinition.showDetailDialog" mat-icon-button class="menuButton" (click)="showInfoDialog($event, element)" >
                <mat-icon class="menuButtonIcon">visibility</mat-icon>
              </button>
              <button *ngIf="tableDefinition.showInlineEditButton" mat-icon-button class="menuButton" (click)="onClickEditInline($event, element)">
                <mat-icon class="menuButtonIcon">edit</mat-icon>
              </button>
            </div>
            <div *ngIf="tableDefinition.menuButtons != null && tableDefinition.menuButtons.length > 0">
              <button mat-icon-button class="menuButton" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" >
                <mat-icon class="menuButtonIcon">more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" [hasBackdrop]="false" >
                <div *ngFor="let btn of tableDefinition.menuButtons">
                  <div *ngIf="btn.showIf == null || btn.showIf(element)">
                    <button mat-menu-item *ngIf="!btn.isHorizontalLine" (click)="btn.action($event, element)">
                      <mat-icon style="transform: scale(70%);" [innerText]="btn.btnIcon"></mat-icon>
                      <span>{{ btn.btnText | translate}}</span>
                    </button>
                    <hr *ngIf="btn.isHorizontalLine" />
                  </div>
                </div>
              </mat-menu>
            </div>
          </div> 
        </div>   

        <div *ngIf="col.type == colType.MediaList">
          <button mat-button (click)="openMediaList($event, col, element)">{{ 'openMediaList' | translate }}</button>
        </div>
        <!-- <div *ngIf="tableColButtons[i] == null && col != ''" [innerHtml]="element[col] | dynamicPipe: tableColsPipes[i]:combinePipeArgs(tableColsPipesArgs[i], element)"></div> -->
        <div *ngIf="col.type != colType.MediaList" [innerHtml]="element[col.name] | dynamicPipe: col.pipe:combinePipeArgs(col.pipeArgs, element)"></div>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row class="tblHeaderRow" *matHeaderRowDef="getDisplayCols()"></tr>
  <tr mat-row class="tblDataRow" *matRowDef="let row; columns: getDisplayCols(); let i = index" [ngClass]="{ 'selected': rowIsSelected(row.id || row.uuid)}"
  (click)="onRowClick($event, row.id || row.uuid)" style="z-index: 90;"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell p-4" [colSpan]="getDisplayCols().length">
      <div class="center" *ngIf="!dataLoaded">
        <mat-spinner class="p-0 m-2" diameter="40"></mat-spinner>
        <div class="ms-2">Lade Daten . . .</div>
      </div>
      <span *ngIf="dataLoaded">Keine Daten verfügbar!</span>
    </td>
  </tr>
</table>

