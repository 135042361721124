<div class="my-4">
    <mat-form-field class="d-flex removeInputBottom">
        <mat-label><span>{{ label | translate}}</span></mat-label>
        <textarea matInput 
        [placeholder]="placeholder" 
        [(ngModel)]="value" 
        [autocomplete]="false" 
        [rows]="rows"
        (change)="onChangeValue()"></textarea>
    </mat-form-field>
    <div class="ms-2" style="font-size: 14px;">{{ hint | translate}}</div>
    <div class="ms-2" style="font-size: 14px; color: red;">{{ errorMsg | translate}}</div>
</div>