import { EventEmitter, Injectable, OnInit } from "@angular/core";
import { RestApiService } from "./restApi.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DataParentService } from "./_dataParent.service";
import { BehaviorSubject, Subject, subscribeOn } from "rxjs";
import { Company, Customer, CustomerCompanyMapping, Login, Register } from "../_shared/classes/Auth";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { ApiResponse, ApiResponseLogin, ApiResponseStatus } from "../_shared/classes/ApiResponse";
import { JwtService } from "./jwt.service";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class DataUserManagementService extends DataParentService implements CanActivate {    
    public customer: Customer[] = []
        
    //AUTHENTIFICATION
    private _currentCustomer = new BehaviorSubject<Customer>(null)
    private _loggedIn = new BehaviorSubject<boolean>(false)

    userToken: BehaviorSubject<string> = new BehaviorSubject<string>('')
    userId: BehaviorSubject<string> = new BehaviorSubject<string>('')
    
    public OnAuthLoadedFinished : EventEmitter<boolean> = new EventEmitter()
    public OnManuelLogin : EventEmitter<boolean> = new EventEmitter()

    public OnCustomersChanged : EventEmitter<Customer[]> = new EventEmitter()
   
        
    constructor(private restApiService: RestApiService,
        private jwtService: JwtService,
        private cookieService: CookieService,
        private router: Router)
    {                
        super(1)
    }  

    public loadData(){
        this.synchCustomers()
    }

    public loadedFinish() {        
        
    }

    public synchCustomers() {
        this.restApiService.getCustomers().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.customer = response.value as Customer[]
                    this.OnCustomersChanged.emit(this.customer)   
                    this.SetNextLoadStep() 
                }
            },
            error: (error) => this.OnCustomersChanged.error(error)
        })
    }
  
    //AUTHENTIFICATION
    loadAuth(): boolean {        
        this.userToken.subscribe({
            next: (userToken: string) => {                                
                this.restApiService.setAuthentification(userToken)
            },
            error: (error) => {
                this.restApiService.setAuthentification('')
            }
        })
  
        if(this.cookieService.check('userToken') && this.cookieService.check('userId')) {
            this.userToken.next(this.cookieService.get('userToken'))
            var token = this.userToken.getValue()
            if(token != null && token != '' && token != 'undefined') this.jwtService.setToken(token)                        
            if(this.jwtService.isTokenExpired()){
                console.log("tokek expired");
                
                this._loggedIn.next(false)
                this._currentCustomer.next(null)
                this.cookieService.delete('userToken')            
                this.cookieService.delete('userId')                    
                this.OnAuthLoadedFinished.emit(false)     
                this.router.navigate(['auth/login']) 
            } else {
                this.restApiService.checkLogin().subscribe({
                    next: (response: ApiResponse) => {                        
                        if(response.status == ApiResponseStatus.Success)  {
                            this._loggedIn.next(true)
                            this.userId.next(this.cookieService.get('userId'))
            
                            this.restApiService.getUserDetails(this.userId.getValue()).subscribe({
                                next: (response: ApiResponse) => {
                                    this._currentCustomer.next(response.value as Customer)
                                    this.OnAuthLoadedFinished.emit(true) 
                                },
                                error: (error) => {
                                    this.logoutUser()
                                    this.OnAuthLoadedFinished.error(error)     
                                }
                            })
                        } else {        
                            this.OnAuthLoadedFinished.emit(false)    
                        } 
                    },
                    error: (error) => {
                        this.logoutUser()
                        this.OnAuthLoadedFinished.error(error)     
                    }
                })
            }
            return true
        } else {
            this._loggedIn.next(false)
            this._currentCustomer.next(null)
            this.OnAuthLoadedFinished.emit(false) 
            
            return false
        }
    }

    get LoginState() {
        return this._loggedIn.asObservable()
    }

    get UserDetails() {
        return this._currentCustomer.asObservable()
    }

    get LastUserDetails() {
        return this._currentCustomer.value
    }
    
    public getIsLoggedIn(): boolean {
        return this._loggedIn.getValue()
    }

    public loginUser(data: Login) {
        var subject = new Subject<ApiResponseLogin>();
        this.restApiService.login(data).subscribe({
            next: (response: ApiResponseLogin) => {  
                if(response.status == ApiResponseStatus.Success) {
                    this.jwtService.setToken(response.token)  
                    this.cookieService.set('userToken', response.token, 30, '/')    
                    this.cookieService.set('userId', response.userId, 30, '/')   
                    this.userId.next(response.userId)                
                    this.userToken.next(response.token)
                    this._loggedIn.next(true)      
                    this.restApiService.getUserDetails(this.userId.getValue()).subscribe({
                        next: (response: ApiResponse) => {                        
                            this._currentCustomer.next(response.value as Customer)
                            this.OnAuthLoadedFinished.emit(true)
                            this.OnManuelLogin.emit(true)
                        },
                        error: (error) => {
                            subject.error(error)
                        }
                    })                                         
                    subject.next(response)
                } else {                  
                    subject.next(response)                  
                }
            },
            error: (error) => subject.error(error)
        })
        return subject.asObservable() 
    }

    // public registerUser(data: Register) {
    //     var subject = new Subject<ApiResponse>();
    //     this.restApiService.register(data).subscribe({
    //         next: (response) => {                                
    //             subject.next(response)
    //         },
    //         error: (error) =>  subject.error(error)
    //     })
    //     return subject.asObservable() 
    // }

    public logoutUser() {
        this.jwtService.setToken('')
        this.cookieService.delete('userId')
        this.cookieService.delete('userToken')
        this._loggedIn.next(false)
        this._currentCustomer.next(null)
        this.OnAuthLoadedFinished.emit(false)      
        this.router.navigate(['auth/login'])
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if(!this._loggedIn.getValue()){            
            this.router.navigate(['auth/login'])
            return false
        }
        return true
    }

    public updateCurrentUser() {       
        this.restApiService.getUserDetails(this.userId.getValue()).subscribe({
            next: (response: ApiResponse) => {                        
                this._currentCustomer.next(response.value as Customer)      
            },
            error: (error) => {
                //TODO
            }
        }) 
    }
}