export class Register {
    username: string
    email: string
    password: string
}

export class Login {
    email: string
    password: string
}

export enum CustomerType {
    private = 0,
    company = 1,
    seller = 2,
}

export enum Salutation {
    notSpecified = 0,
    woman = 1,
    men = 2,
}

export class Customer {    
    uuid: string
    accountType: CustomerType
    firstname: string
    name: string
    email: string
    password: string
    phone: string
    street: string
    streetNumber: string
    zip: string
    city: string
    country: string

    companyName: string
    taxId: string
    website: string
    logo: string
    companyDescription: string
    iban: string

    isAdmin: boolean
    emailActivated: boolean
    phoneActivated: boolean
    newsletterActive: boolean
    deleteDate: Date
    stripeCustomerId: string
    stripeConnectId: string
        
    public constructor(init?:Partial<Customer>) {
        Object.assign(this, init);
    }

    public getFullName(): string {
        return this.firstname + ' ' + this.name
    }
}

export class Company {    
    uuid: string
    name: string
    namePath: string
    email: string
    phone: string
    street: string
    streetNumber: string
    plz: string
    city: string
    country: string
    emailActivated: boolean
    phoneActivated: boolean
    vatId: string 
    deleteDate: Date
    website: string
    logo: string
    percentage: number    
    stripeAccountId: string 

    public constructor(init?:Partial<Company>) {
        Object.assign(this, init);
    }
}

export class CompanyCreation extends Company {
    creatorUuid: string
    
    public constructor(init?:Partial<CompanyCreation>) {
        super();
        Object.assign(this, init);
    }
}

export enum CustomerCompanyAccessLevel {
    Owner = 1,
    Creator = 2,
}

export class CustomerCompanyMapping {    
    id: number
    customerUuid: string
    companyUuid: string
    accessLevel: number
    
    public constructor(init?:Partial<CustomerCompanyMapping>) {
        Object.assign(this, init);
    }
}

export class PasswortReset {    
    id: number
    uuid: string
    customerUuid: string
    
    public constructor(init?:Partial<PasswortReset>) {
        Object.assign(this, init);
    }
}