import { Component, ViewChild, OnInit } from '@angular/core';
import { DynamicPipeType } from '../../_pipes/dynamic.pipe';
import { DataUserManagementService } from '../../_services/data.usermanagement.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from '../../_services/error.service';
import { Customer, CustomerType } from '../../_shared/classes/Auth';
import { RestApiService } from '../../_services/restApi.service';
import { ApiResponse, ApiResponseStatus } from '../../_shared/classes/ApiResponse';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ConfigTableButtonDef, ConfigTableColDef, ConfigTableColType, ConfigTableComponent, ConfigTableDef } from '../../_khLib/components/config-table/config-table.component';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DynamicDialogComponent } from '../../_khLib/components/dynamic-dialog/dynamic-dialog.component';
import { ConfigTableColSelectionType } from '../../_services/helper.service';
import { DataSettingService } from '../../_services/data.settings.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  cfgTableCustomers = new ConfigTableDef<Customer>(Customer, {
    tblName: 'customers',
    infoDialogTitleFunction: (e) => { return e.firstname + ' ' + e.name},
    cols: [
      new ConfigTableColDef({name: 'uuid', title: 'displayName', type: ConfigTableColType.Uuid, showInTable: true,  pipe: DynamicPipeType.Customer}),
      new ConfigTableColDef({name: 'accountType',           type: ConfigTableColType.Selection, showInTable: true,  selectionType: ConfigTableColSelectionType.AccountType, pipe: DynamicPipeType.AccountType}),
      new ConfigTableColDef({name: 'firstname',             type: ConfigTableColType.Text,      showInTable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'name',                  type: ConfigTableColType.Text,      showInTable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'email',                 type: ConfigTableColType.EMail,     showInTable: true,  pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'phone',                 type: ConfigTableColType.Text,      showInTable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'street',                type: ConfigTableColType.Text,      showInTable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'streetNumber',          type: ConfigTableColType.Text,      showInTable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'zip',                   type: ConfigTableColType.Text,      showInTable: true,  pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'city',                  type: ConfigTableColType.Text,      showInTable: true,  pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'country',               type: ConfigTableColType.Selection, showInTable: true,  selectionType: ConfigTableColSelectionType.Country, filterWidth: '150px', pipe: DynamicPipeType.Country}),

      new ConfigTableColDef({name: 'companyName',           type: ConfigTableColType.Text,      showInTable: true,  pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'taxId',                 type: ConfigTableColType.Text,      showInTable: false,  pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'website',               type: ConfigTableColType.Text,      showInTable: false,  pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'logo',                  type: ConfigTableColType.Text,      showInTable: false,  pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'companyDescription',    type: ConfigTableColType.Text,      showInTable: false,  pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'iban',                  type: ConfigTableColType.Text,      showInTable: false,  pipe: DynamicPipeType.None}),

      new ConfigTableColDef({name: 'isAdmin',               type: ConfigTableColType.Selection, showInTable: false, selectionType: ConfigTableColSelectionType.Bool, pipe: DynamicPipeType.BoolToText, defaultValue: false}),
      new ConfigTableColDef({name: 'emailActivated',        type: ConfigTableColType.Selection, showInTable: true,  selectionType: ConfigTableColSelectionType.Bool, pipe: DynamicPipeType.BoolToText, defaultValue: false}),
      new ConfigTableColDef({name: 'phoneActivated',        type: ConfigTableColType.Selection, showInTable: false, selectionType: ConfigTableColSelectionType.Bool, pipe: DynamicPipeType.BoolToText, defaultValue: false}),
      new ConfigTableColDef({name: 'newsletterActive',      type: ConfigTableColType.Selection, showInTable: true,  selectionType: ConfigTableColSelectionType.Bool, pipe: DynamicPipeType.BoolToText, defaultValue: false}),
      new ConfigTableColDef({name: 'deleteDate',            type: ConfigTableColType.Date,      showInTable: true,  pipe: DynamicPipeType.Date}),
      new ConfigTableColDef({name: 'stripeCustomerId',      type: ConfigTableColType.Text,      showInTable: false,  pipe: DynamicPipeType.None, isEditable: false }),
      new ConfigTableColDef({name: 'stripeConnectId',      type: ConfigTableColType.Text,      showInTable: false,  pipe: DynamicPipeType.None, isEditable: false }),
    ], 
    menuButtons: [
      new ConfigTableButtonDef({ btnIcon: 'toggle_on', btnText: 'customerBtnEmailActivOn', showIf:(c) => c.emailActivated == false, action: (e,c) => this.onClickCustomerEmailActiveOn(e,c)}),
      new ConfigTableButtonDef({ btnIcon: 'toggle_off', btnText: 'customerBtnEmailActivOff', showIf:(c) => c.emailActivated == true, action: (e,c) => this.onClickCustomerEmailActiveOff(e,c) }),
      new ConfigTableButtonDef({ btnIcon: 'toggle_on', btnText: 'customerBtnNewsletterActivOn', showIf:(c) => c.newsletterActive == false, action: (e,c) => this.onClickCustomerNewsletterActiveOn(e,c) }),
      new ConfigTableButtonDef({ btnIcon: 'toggle_off', btnText: 'customerBtnNewsletterActivOff', showIf:(c) => c.newsletterActive == true, action: (e,c) => this.onClickCustomerNewsletterActiveOff(e,c) }),
      new ConfigTableButtonDef({ isHorizontalLine: true }),
      new ConfigTableButtonDef({ btnIcon: 'add_circle', btnText: 'customerBtnCreateStripeCustomer', showIf:(c) => c.stripeCustomerId == null, action: (e,c) => this.onClickCreateStripeCustomer(e,c) }),
      new ConfigTableButtonDef({ btnIcon: 'add_circle', btnText: 'customerBtnCreateStripeConnect', showIf:(c) => c.accountType == CustomerType.seller && c.stripeConnectId == null, action: (e,c) => this.onClickCreateStripeConnect(e,c) }),
      new ConfigTableButtonDef({ btnIcon: 'cancel', btnText: 'customerBtnDeleteStripeConnect', showIf:(c) => c.accountType == CustomerType.seller && c.stripeConnectId != null, action: (e,c) => this.onClickDeleteStripeConnect(e,c) }),
      new ConfigTableButtonDef({ isHorizontalLine: true, showIf:(c) => c.stripeCustomerId == null || c.accountType == CustomerType.seller }),
      new ConfigTableButtonDef({ btnIcon: 'delete', btnText: 'customerBtnDeleteFinally', action: (e,c) => this.onClickCustomerDeleteFinally(e,c) }),
    ],

    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createCustomer(c),
    onCreate: (object, result) => this.usermanagement.synchCustomers(),
    onCreateTextLoading: (): string => this.trans_createCustomer,
    onCreateTextLoaded: (): string => this.trans_createdCustomer,
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.changeCustomer(c),
    onEdit: (object, result) => this.usermanagement.synchCustomers(),
    onEditTextLoading: (): string => this.trans_editCustomer,
    onEditTextLoaded: (): string => this.trans_editedCustomer,

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteCustomer(ids),
    onDelete: (object, result) => this.usermanagement.synchCustomers(),
    onDeleteTextLoading: (): string => this.trans_deleteCustomer,
    onDeleteTextLoaded: (): string => this.trans_deletedCustomer,

    listNameColumnFunction: (c) => c.firstname + ' ' + c.name,
  })
  cfgTableCustomersData = this.usermanagement.customer
  @ViewChild('tblCustomers') tblCustomers: ConfigTableComponent
  
  fileName = ''

  trans_createCustomer = ''
  trans_createdCustomer = ''
  trans_editCustomer = ''
  trans_editedCustomer = ''
  trans_deleteCustomer = ''
  trans_deletedCustomer = ''
  trans_emailActivateCustomer = ''
  trans_emailActivatedCustomer = ''
  trans_newsletterActivateCustomer = ''
  trans_newsletterActivatedCustomer = ''
  trans_emailDeactivateCustomer = ''
  trans_emailDeactivatedCustomer = ''
  trans_newsletterDeactivateCustomer = ''
  trans_newsletterDeactivatedCustomer = ''

  constructor(private usermanagement: DataUserManagementService,
    private http: HttpClient,
    private errorService: ErrorService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private restApiService: RestApiService,
    private dataSettings: DataSettingService,
    private translateService: TranslateService){
      this.onLoadLangTexts()
      translateService.onLangChange.subscribe(() => this.onLoadLangTexts())

  }

  onLoadLangTexts(): void {
    this.translateService.get('customerInfoCreated').subscribe((res: string) => this.trans_createdCustomer = res)
    this.translateService.get('customerInfoCreate').subscribe((res: string) => this.trans_createCustomer = res)
    this.translateService.get('customerInfoEdit').subscribe((res: string) => this.trans_editCustomer = res)
    this.translateService.get('customerInfoEdited').subscribe((res: string) => this.trans_editedCustomer = res)
    this.translateService.get('customerInfoDelete').subscribe((res: string) => this.trans_deleteCustomer = res)
    this.translateService.get('customerInfoDeleted').subscribe((res: string) => this.trans_deletedCustomer = res)
    this.translateService.get('customerInfoEmailActivate').subscribe((res: string) => this.trans_emailActivateCustomer = res)
    this.translateService.get('customerInfoEmailActivated').subscribe((res: string) => this.trans_emailActivatedCustomer = res)
    this.translateService.get('customerInfoNewsletterActivate').subscribe((res: string) => this.trans_newsletterActivateCustomer = res)
    this.translateService.get('customerInfoNewsletterActivated').subscribe((res: string) => this.trans_newsletterActivatedCustomer = res)
    this.translateService.get('customerInfoEmailDeactivate').subscribe((res: string) => this.trans_emailDeactivateCustomer = res)
    this.translateService.get('customerInfoEmailDeactivated').subscribe((res: string) => this.trans_emailDeactivatedCustomer = res)
    this.translateService.get('customerInfoNewsletterDeactivate').subscribe((res: string) => this.trans_newsletterDeactivateCustomer = res)
    this.translateService.get('customerInfoNewsletterDeactivated').subscribe((res: string) => this.trans_newsletterDeactivatedCustomer = res)
  }

  ngOnInit(): void {
    this.usermanagement.OnCustomersChanged.subscribe({ next: (response) => {
      this.tblCustomers.refreshData(response)
    } })
  }  
  
  private onClickCustomerEmailActiveOn(event: Event, customer: Customer) { 
    this.doCustomerEmailActive(customer.uuid, true, this.trans_emailActivateCustomer, this.trans_emailActivatedCustomer)
  }

  private onClickCustomerEmailActiveOff(event: Event, customer: Customer) {  
    this.doCustomerEmailActive(customer.uuid, false, this.trans_emailDeactivateCustomer, this.trans_emailDeactivatedCustomer)
  }

  private doCustomerEmailActive(uuid: string, state: boolean, text1: string, text2: string) {
    var snackbar = this.snackbar.open(text1, '', { horizontalPosition: 'right', verticalPosition: 'top' })
    this.restApiService.setCustomerEmailActivation(uuid, state).subscribe({
        next: (response: ApiResponse) => {
            snackbar.dismiss()         
            if(response.status == ApiResponseStatus.Success) {     
              this.usermanagement.synchCustomers()
              this.snackbar.open(text2, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 })
            } else {
              this.translateService.get(response.message).subscribe((res: string) =>  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
            }
        },
        error: (error) => this.errorService.OnError.error(error)
    }) 
  }

  private onClickCustomerNewsletterActiveOn(event: Event, customer: Customer) {  
    this.doCustomerNewsletterActive(customer.uuid, true, this.trans_newsletterActivateCustomer, this.trans_newsletterActivatedCustomer)
  }

  private onClickCustomerNewsletterActiveOff(event: Event, customer: Customer) {  
    this.doCustomerNewsletterActive(customer.uuid, false, this.trans_newsletterDeactivateCustomer, this.trans_newsletterDeactivatedCustomer)
  }

  private doCustomerNewsletterActive(uuid: string, state: boolean, text1: string, text2: string) {
    var snackbar = this.snackbar.open(text1, '', { horizontalPosition: 'right', verticalPosition: 'top' })
    this.restApiService.setCustomerNewsletter(uuid, state).subscribe({
        next: (response: ApiResponse) => {
            snackbar.dismiss()         
            if(response.status == ApiResponseStatus.Success) {     
              this.usermanagement.synchCustomers()
              this.snackbar.open(text2, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 })
            } else {
              this.translateService.get(response.message).subscribe((res: string) =>  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
            }
        },
        error: (error) => this.errorService.OnError.error(error)
    }) 
  }

  private onClickCreateStripeCustomer(event: Event, customer: Customer) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true

    let dialogRef = this.dialog.open(DynamicDialogComponent, dialogConfig)
    dialogRef.componentInstance.title = 'customerBtnCreateStripeCustomer'
    dialogRef.componentInstance.text = 'customerBtnCreateStripeCustomerDialogText'
    dialogRef.afterClosed().subscribe((result) => {
      if(result) {
        this.restApiService.createStripeCustomer(customer.uuid).subscribe({
          next: (response: ApiResponse) => {
            if(response.status == ApiResponseStatus.Success) {
              this.usermanagement.synchCustomers()
            } else {
              this.translateService.get(response.message).subscribe((res: string) =>  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
            }
          },
          error: (error) => this.errorService.OnError.error(error)
        })
      }
    })
  }

  private onClickCreateStripeConnect(event: Event, customer: Customer) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true

    let dialogRef = this.dialog.open(DynamicDialogComponent, dialogConfig)
    dialogRef.componentInstance.title = 'customerBtnCreateStripeConnect'
    dialogRef.componentInstance.text = 'customerBtnCreateStripeConnectDialogText'
    dialogRef.afterClosed().subscribe((result) => {
      if(result) {
        this.restApiService.createStripeConnect(customer.uuid).subscribe({
          next: (response: ApiResponse) => {
            if(response.status == ApiResponseStatus.Success) {
              this.usermanagement.synchCustomers()
            } else {
              this.translateService.get(response.message).subscribe((res: string) =>  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
            }
          },
          error: (error) => this.errorService.OnError.error(error)
        })
      }
    })
  }

  private onClickDeleteStripeConnect(event: Event, customer: Customer) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true

    let dialogRef = this.dialog.open(DynamicDialogComponent, dialogConfig)
    dialogRef.componentInstance.title = 'customerBtnDeleteStripeConnect'
    dialogRef.componentInstance.text = 'customerBtnDeleteStripeConnectDialogText'
    dialogRef.afterClosed().subscribe((result) => {
      if(result) {
        this.restApiService.deleteStripeConnect(customer.uuid).subscribe({
          next: (response: ApiResponse) => {
            if(response.status == ApiResponseStatus.Success) {
              this.usermanagement.synchCustomers()
            } else {
              this.translateService.get(response.message).subscribe((res: string) =>  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
            }
          },
          error: (error) => this.errorService.OnError.error(error)
        })
      }
    })
  }
 
  private onClickCustomerDeleteFinally(event: Event, customer: Customer) {  
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true

    let dialogRef = this.dialog.open(DynamicDialogComponent, dialogConfig)
    dialogRef.componentInstance.title = 'customerBtnDeleteFinally'
    dialogRef.componentInstance.text = 'customerBtnDeleteFinallyDialogText'
    dialogRef.componentInstance.listItems.push(customer.firstname + ' ' + customer.name)
    dialogRef.afterClosed().subscribe((result) => {
      if(result) {
        this.restApiService.deleteFinallyCustomer(customer.uuid).subscribe({
          next: (response: ApiResponse) => {
            if(response.status == ApiResponseStatus.Success) {     
              this.usermanagement.synchCustomers()
            } else {
              this.translateService.get(response.message).subscribe((res: string) =>  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
            }
          },
          error: (error) => this.errorService.OnError.error(error)
        })
      }
    })
  }

}
