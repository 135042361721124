// export class ApiResponse {
//     title: string
//     desc: string
//     lastInsertId: number
//     data: any
//     errorStatus: number
// }

export enum ApiResponseStatus {
    Success = 0,
    Error = 1, 
    Warning = 2,
    Info = 3,
}

export class ApiResponse {
    status: ApiResponseStatus
    message: string
    details: string
    lastInsertId: number
    value: any
}



export class ApiResponseLogin {
    userId: string
    token: string
    expiration: Date
    status: ApiResponseStatus
    message: string    
}