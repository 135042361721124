import { DialogModule, DialogRef } from '@angular/cdk/dialog';
import { Component, Input, OnInit, Type } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DynamicPipe, DynamicPipeType } from 'src/app/_pipes/dynamic.pipe';
import { DataSettingService } from 'src/app/_services/data.settings.service';
import { ConfigTableInfoDialogComponent } from '../config-table-info-dialog/config-table-info-dialog.component';
import { ConfigDynamicFormComponent, DynamicFormResult } from '../config-dynamic-form/config-dynamic-form.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogComponent } from '../dynamic-dialog/dynamic-dialog.component';
import { ApiResponse, ApiResponseStatus } from 'src/app/_shared/classes/ApiResponse';
import { Customer } from 'src/app/_shared/classes/Auth';
import { ErrorService } from 'src/app/_services/error.service';
import { ConfigDynamicFormDialogComponent } from '../config-dynamic-form-dialog/config-dynamic-form-dialog.component';
import { ConfigTableColSelectionType, HelperService } from 'src/app/_services/helper.service';
import { ConfigMedialistComponent } from '../config-medialist/config-medialist.component';

export enum ConfigTableColType {
  None,
  Text,
  TextArea,
  Number,
  Date,
  EMail,
  Uuid,
  Selection,
  SelectionMulti,
  Password,
  DisplayText,
  CheckBox,
  Image,
  File,
  MediaList,
  TextEditor,
  TranslationEditor,

  CREATE_ROW,
  FINISH_ROW,
  SPACE,
}

export class ConfigTableDef<T>
{
  tblName: string
  cols: ConfigTableColDef[]
  actions: ConfigTableButtonDef[]
  actionsMultipleElement: ConfigTableButtonDef[]
  menuButtons: ConfigTableButtonDef[]
  formButtons: ConfigTableFormButton[]
  showDetailDialog: boolean = true
  showInlineEditButton: boolean = false

  infoDialogTitle: string = null
  infoDialogTitleFunction: Function

  dialogAddEditWidth: string = '600px'

  dialogInfoHeight: string = '400px'
  dialogInfoWidth: string = '600px'

  rowClickable: boolean = true

  canAdd: boolean = true
  canEditDelete: boolean = true
  canDeleteAll: boolean = false

  onCreate: Function
  onCreateCancel: Function
  onEdit: Function
  onDelete: Function

  createBeforeEditData: boolean
  onCreateApi: Function
  onCreateTextLoading: Function
  onCreateTextLoaded: Function

  onEditApi: Function
  onEditTextLoading: Function
  onEditTextLoaded: Function
  
  onDeleteApi: Function
  onDeleteTextLoading: Function
  onDeleteTextLoaded: Function

  onSynch: Function
  
  uploadImage: Function
  sortImage: Function
  deleteImage: Function

  listNameColumn: string
  listNameColumnFunction: Function

  rowNameFunction: Function

  showTblnameInTitle: boolean
 
  
  getNew() { return new this.tableType() }



  getColAsString(){
    return this.cols.map(c => c.name)
  }
  
  getColumnTitle(col: ConfigTableColDef) {
    return this.tblName + '_' + (col.title != null ? col.title : col.name)
  }
  

  parseFromResult(inputs: DynamicFormResult[]) {
    var newObject = this.getNew()    
    this.cols.forEach(c => {
      if(c.isEditable) {
        var element = inputs.find(i => i.Name == c.name) 
        if(element != null) {
          if(c.type == ConfigTableColType.Selection && c.selectionType == ConfigTableColSelectionType.Bool) {            
            newObject[c.name] = element.Value || element.Value == 'true'
          } else {
            newObject[c.name] = element.Value
          }
        }
      }
    })
    return newObject
  }

  public constructor(private tableType: new () => T,
    init?:Partial<ConfigTableDef<T>>,    
  ) {
    Object.assign(this, init);
  }
}

export class ConfigTableColDef
{
  name: string
  title: string
  pipe: DynamicPipeType
  showInTable: boolean = false
  pipeArgs: any[] = []
  buttons: ConfigTableButtonDef[]
  sortable: boolean = true
  filterable: boolean = true
  filterWidth: string = '100px'
  type: ConfigTableColType = ConfigTableColType.None
  selectionType: ConfigTableColSelectionType = ConfigTableColSelectionType.None
  isEditable: boolean = true
  defaultValue: any = null
  dynamicData: any
  onSendFileApi: Function

  public constructor(init?:Partial<ConfigTableColDef>) {
    Object.assign(this, init);
  }
}

export class ConfigTableButtonDef {
  btnText: string
  btnIcon: string
  action: Function
  showIf: Function
  btnClass: string
  isFab: boolean = false
  isHorizontalLine?: boolean = false
  
  public constructor(init?:Partial<ConfigTableButtonDef>) {
    Object.assign(this, init);
  }
}

export class ConfigTableDropDownEntry {
  key: any
  value: string

  public constructor(init?:Partial<ConfigTableDropDownEntry>) {
    Object.assign(this, init);
  }
}

export class ConfigTableFormButton {
  title: string
  color: string = 'primary'
  action: Function
  btnClass: string
  
  public constructor(init?:Partial<ConfigTableFormButton>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'app-config-table',
  templateUrl: './config-table.component.html',
  styleUrls: ['./config-table.component.scss']
})
export class ConfigTableComponent implements OnInit {

  @Input() tableDefinition: ConfigTableDef<any>
  @Input() tableData: {}[] = []

  colType = ConfigTableColType

  dataSource = new MatTableDataSource<any>()
  allData: any[] = []

  dataLoaded: boolean = false

  dropDownInitValues: ConfigTableDropDownEntry[][] = []
  dropDownValues: ConfigTableDropDownEntry[][] = []
  
  filterForm = new FormGroup({})

  selectedRowIds: Set<any> = new Set<any>();

  constructor(
    private settingsData: DataSettingService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private errorService: ErrorService,
    private helperService: HelperService,
    private dynamicPipe: DynamicPipe) {
      this.onLoadLangTexts()
      translateService.onLangChange.subscribe(() => this.onLoadLangTexts())
  }

  onLoadLangTexts(): void {
    
  }

  ngOnInit() {        
    this.dataSource = new MatTableDataSource(this.tableData)       

    //Action col
    if(((this.tableDefinition.menuButtons != null && this.tableDefinition.menuButtons.length > 0) || this.tableDefinition.showDetailDialog || this.tableDefinition.showInlineEditButton)) {      
      this.tableDefinition.cols.push(new ConfigTableColDef({name: 'actions', type: ConfigTableColType.None, filterable: false, sortable: false, showInTable: true}))
    }
    
    this.helperService.OnDataChanged.subscribe({ next: (response) => 
    {
        this.loadDropDownValues(response)
    }})
    this.loadDropDownValues(this.helperService.getAllDropDownValues())
    
    this.dataLoaded = true
  }

  loadDropDownValues(values: ConfigTableDropDownEntry[][]) {
    this.dropDownInitValues = values

    this.tableDefinition.cols.forEach(col => {      
      //Date has no filter
      if(col.type == ConfigTableColType.Date) {
        col.filterable = false
      } else {
        this.filterForm.addControl(col.name, new FormControl(''))
      }      

      if(col.type == ConfigTableColType.Selection || col.type == ConfigTableColType.SelectionMulti) {
        this.dropDownValues[col.name] = []
        this.dropDownValues[col.name].push(new ConfigTableDropDownEntry({key: -1, value: '-'}))
        this.dropDownValues[col.name].push(...this.dropDownInitValues[col.selectionType])
      }               
    })
  }

  //#region table functions
  combinePipeArgs(args1: any[], arg2: any) {
    if(args1 != null && arg2 != null) return [... args1, arg2]
    if(args1 != null) return [... args1]
    if(arg2 != null) return [arg2]
    return null    
  }

  refreshData(data: any[]): void {
    this.allData = data
    this.filterData()
    //this.selectedRowIds.clear()
  }
  
  onRowClick(event, id: any) {   
    if(!this.tableDefinition.rowClickable) return
    if(this.selectedRowIds.has(id)) {
     this.selectedRowIds.delete(id);
    }
    else {
      this.selectedRowIds.add(id);
    }
  }

  rowIsSelected(id: any) {
    return this.selectedRowIds.has(id);
  }

  getSelectedRows(){
    return this.dataSource.data.filter(x => this.selectedRowIds.has(x.id || x.uuid));
  }

  getDisplayCols(){    
    return this.tableDefinition.cols.filter(c => c.showInTable).map(c => c.name)
  }

  //#endregion

  //#region table sort
  sortData(sort: Sort) {    
    const data = this.dataSource.data
    if (!sort.active || sort.direction === '') {
      this.refreshData(data)
      return
    }

    var sortData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc'
      return this.compare(a[sort.active], b[sort.active], isAsc)      
    })

    this.refreshData(sortData)
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
  }
  //#endregion

  //#region table filter
  filterData() {
    var data = this.allData    
    
    Object.keys(this.filterForm.controls).forEach(key => {
      var value = this.filterForm.get(key).value
            
      if(value != null && value.toString() != '' && value != -1) {     
        var col = this.tableDefinition.cols.find(c => c.name == key)
        data = data.filter(e => this.filter(e, col, key, value))
      }      
    })

    this.dataSource.data = data
  }

  filter(e, col, key, value: string) {        
    if(col.type == ConfigTableColType.Selection) {     
      
      if(col.selectionType == ConfigTableColSelectionType.Bool) {
        return JSON.parse(e[key]) == JSON.parse(value)
      } else {        
        return e[key] == value
      }
    } else {     
      if(e[key] == null) return false

      var targetValue = this.dynamicPipe.transform(e[key], col.pipe, col.pipeArgs)
      if(value.endsWith('*')) {        
        return targetValue.toString().toLowerCase().startsWith(value.slice(0, -1).toLowerCase())
      } else if(value.startsWith('*')) {        
        return targetValue.toString().toLowerCase().endsWith(value.slice(1, value.length).toLowerCase())
      } else {
        return targetValue.toString().toLowerCase().includes(value.toLowerCase())
      }
    }
  }

  onSearchInFilter(element, colName) { 
    
    var col = this.tableDefinition.cols.find(c => c.name == colName)     
    var values = this.dropDownInitValues[col.selectionType]
    
    values = values.filter(v => v.value.toLowerCase().includes(element.value.toLowerCase()))

    this.dropDownValues[colName] = values
  }
  //#endregion
  
  showInfoDialog(event, element) {
    event.stopPropagation()

    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true
    // dialogConfig.height = this.tableDefinition.dialogInfoHeight
    dialogConfig.width = this.tableDefinition.dialogInfoWidth

    let dialogRef = this.dialog.open(ConfigTableInfoDialogComponent, dialogConfig)
    dialogRef.componentInstance.setData(this.tableDefinition, element)
    return dialogRef
  }

  onClickEditInline(event, element) {
    event.stopPropagation()
    this.onClickEdit(element)
  }

  openDialog(title: string, data: any) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true
    //dialogConfig.height = this.tableCreateData.dialogHeight
    dialogConfig.width = this.tableDefinition.dialogAddEditWidth

    let dialogRef = this.dialog.open(ConfigDynamicFormDialogComponent, dialogConfig)
    dialogRef.componentInstance.setData(this.tableDefinition, null, title, 'btn_close', 'btn_save', data)
    return dialogRef
  }

  onClickAdd() {
    if(this.tableDefinition.createBeforeEditData) {
      if(this.tableDefinition.onCreateApi != null) {
      
        this.tableDefinition.onCreateApi({}).subscribe({
          next: (response: ApiResponse) => {            
            if(response.status == ApiResponseStatus.Success) {   
              // if(response.value.id != null) addData.id = response.value.id
              // if(response.value.uuid != null) addData.uuid = response.value.uuid  
              this.doEditDialog(response.value, response.value)
            } else {             
              
            }
          },
          error: (error) => this.errorService.OnError.error(error)
        })
      } else {
        this.tableDefinition.onCreate()  
      }
    } else {
      this.doAddDialog(null)
    }   
  }

  doAddDialog(addData) {
    var dialogRef = this.openDialog('tbl_add', addData)

    dialogRef.afterClosed().subscribe(result => {
      if(result != null) {
        var newObject = this.tableDefinition.parseFromResult(result)        

        if(this.tableDefinition.onCreateApi != null) {
          var snackbar = null
          
          if(this.tableDefinition.onCreateTextLoading != null) {            
            this.translateService.get(this.tableDefinition.onCreateTextLoading()).subscribe((res: string) => 
              snackbar = this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top' }))
          }
          
          this.tableDefinition.onCreateApi(newObject).subscribe({
            next: (response: ApiResponse) => {
              if(response.status == ApiResponseStatus.Success) {     
                var count = 0

                //First count
                this.tableDefinition.cols.forEach(col => {
                  if((col.type == ConfigTableColType.Image || col.type == ConfigTableColType.File) && col.onSendFileApi != null) count++
                })

                //then send
                if(count > 0) {
                  this.tableDefinition.cols.forEach(col => {
                    if((col.type == ConfigTableColType.Image || col.type == ConfigTableColType.File) && col.onSendFileApi != null){                                          
                      col.onSendFileApi(response.lastInsertId, newObject[col.name]).subscribe({
                          next: (response: ApiResponse) => {
                              if(response.status == ApiResponseStatus.Success) {
                                if(--count == 0) {
                                  if(snackbar != null) {
                                    snackbar.dismiss()  
                                  }       
                                  this.tableDefinition.onCreate(newObject, result)  
                                  if(this.tableDefinition.onCreateTextLoaded != null) {
                                    this.translateService.get(this.tableDefinition.onCreateTextLoaded()).subscribe((res: string) => 
                                      this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
                                  }
                                }
                              } else {
                                this.translateService.get(response.message).subscribe((res: string) =>  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
                              }
                          },
                          error: (error) => this.errorService.OnError.error(error)
                      })
                    }
                  })
                } else {
                  if(snackbar != null) {
                    snackbar.dismiss()  
                  }       
                  this.tableDefinition.onCreate(newObject, result)  
                  if(this.tableDefinition.onCreateTextLoaded != null) {
                    this.translateService.get(this.tableDefinition.onCreateTextLoaded()).subscribe((res: string) => 
                      this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
                  }
                }
              } else {
                if(snackbar != null) {
                  snackbar.dismiss()  
                }       
                this.translateService.get(response.message).subscribe((res: string) =>  
                  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
              }
            },
            error: (error) => this.errorService.OnError.error(error)
          })
        } else {
          this.tableDefinition.onCreate(newObject, result)  
        }
      }
    })
  }

  onClickEdit(inlineElement = null) {
    var selected: any[] = []

    if(inlineElement != null) {
      selected.push(inlineElement)
    } else {
      selected = this.getSelectedRows()
    }

    if(selected.length != 1) return    
    var data = this.dataSource.data.find(d => selected[0].id != null ? d.id == selected[0].id : d.uuid == selected[0].uuid)  
    
    this.doEditDialog(data, selected[0])
  }

  doEditDialog(data, selected){
    var dialogRef = this.openDialog('tbl_edit', data)
    dialogRef.afterClosed().subscribe(result => {
      if(result != null) {
        var newObject = this.tableDefinition.parseFromResult(result)
        if(selected.id != null) newObject.id = selected.id
        if(selected.uuid != null) newObject.uuid = selected.uuid

        if(this.tableDefinition.onEditApi != null) {
          var snackbar = null
          
          if(this.tableDefinition.onEditTextLoading != null) {
            this.translateService.get(this.tableDefinition.onEditTextLoading()).subscribe((res: string) => 
              snackbar = this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top' }))
          }
          
          this.tableDefinition.onEditApi(newObject).subscribe({
            next: (response: ApiResponse) => {
              if(response.status == ApiResponseStatus.Success) {    
                var count = 0
                
                //First count
                this.tableDefinition.cols.forEach(col => {
                  if((col.type == ConfigTableColType.Image || col.type == ConfigTableColType.File) && col.onSendFileApi != null && selected.image != newObject[col.name]) count++
                })

                //then send
                if(count > 0) {
                  this.tableDefinition.cols.forEach(col => {
                    if((col.type == ConfigTableColType.Image || col.type == ConfigTableColType.File) && col.onSendFileApi != null && selected.image != newObject[col.name]){
                      var id = newObject.uuid || newObject.id
                      col.onSendFileApi(id, newObject[col.name]).subscribe({
                          next: (response: ApiResponse) => {
                              if(response.status == ApiResponseStatus.Success) {
                                if(--count == 0) {
                                  if(snackbar != null) {
                                    snackbar.dismiss()  
                                  }       
                                  this.tableDefinition.onEdit(newObject, result, data)   
                                  if(this.tableDefinition.onEditTextLoaded != null) {
                                    this.translateService.get(this.tableDefinition.onEditTextLoaded()).subscribe((res: string) => 
                                      this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
                                  }
                                }
                              } else {
                                this.translateService.get(response.message).subscribe((res: string) =>  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
                              }
                          },
                          error: (error) => this.errorService.OnError.error(error)
                      })
                    }
                  })
                } else {
                  if(snackbar != null) {
                    snackbar.dismiss()  
                  }       
                  this.tableDefinition.onEdit(newObject, result, data)   
                  if(this.tableDefinition.onEditTextLoaded != null) {
                    this.translateService.get(this.tableDefinition.onEditTextLoaded()).subscribe((res: string) => 
                      this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
                  }
                }
              } else {
                if(snackbar != null) {
                  snackbar.dismiss()  
                }       
                this.translateService.get(response.message).subscribe((res: string) =>  
                  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
              }
            },
            error: (error) => this.errorService.OnError.error(error)
          })
        } else {
          this.tableDefinition.onEdit(newObject, result, data)   
        }     
      }
    })
  }

  onClickCopy() {

    if(this.tableDefinition.createBeforeEditData) {
      if(this.tableDefinition.onCreateApi != null) {
      
        this.tableDefinition.onCreateApi({}).subscribe({
          next: (response: ApiResponse) => {            
            if(response.status == ApiResponseStatus.Success) {   
              // if(response.value.id != null) addData.id = response.value.id
              // if(response.value.uuid != null) addData.uuid = response.value.uuid  
              
              var selected = this.getSelectedRows()
              if(selected.length != 1) return    
              var data = this.dataSource.data.find(d => selected[0].id != null ? d.id == selected[0].id : d.uuid == selected[0].uuid)  

              this.tableDefinition.cols.filter(col => col.isEditable && col.type != ConfigTableColType.TranslationEditor).forEach(col => {
                response.value[col.name] = data[col.name]
              })

              if(response.value.id != null) selected['id'] = response.value.id
              if(response.value.uuid != null) selected['uuid'] = response.value.uuid

              this.doEditDialog(response.value, selected)
            } else {             
              
            }
          },
          error: (error) => this.errorService.OnError.error(error)
        })
      } else {
        this.tableDefinition.onCreate()  
      }
    } else {
      this.doCopyDialog()
    }      
  }

  
  doCopyDialog(){
    var selected = this.getSelectedRows()
    if(selected.length != 1) return    
    var data = this.dataSource.data.find(d => selected[0].id != null ? d.id == selected[0].id : d.uuid == selected[0].uuid)  
    var dialogRef = this.openDialog('tbl_edit', data)
    dialogRef.afterClosed().subscribe(result => {
      if(result != null) {
        var newObject = this.tableDefinition.parseFromResult(result)
        if(this.tableDefinition.onCreateApi != null) {
          var snackbar = null
          
          if(this.tableDefinition.onCreateTextLoading != null) {
            this.translateService.get(this.tableDefinition.onCreateTextLoading()).subscribe((res: string) => 
              snackbar = this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top' }))
          }
          
          this.tableDefinition.onCreateApi(newObject).subscribe({
            next: (response: ApiResponse) => {
              if(response.status == ApiResponseStatus.Success) {    
                var count = 0
                
                //First count
                this.tableDefinition.cols.forEach(col => {
                  if((col.type == ConfigTableColType.Image || col.type == ConfigTableColType.File) && col.onSendFileApi != null && selected[0].image != newObject[col.name]) count++
                })

                //then send
                if(count > 0) {
                  this.tableDefinition.cols.forEach(col => {
                    if((col.type == ConfigTableColType.Image || col.type == ConfigTableColType.File) && col.onSendFileApi != null && selected[0].image != newObject[col.name]){
                      var id = newObject.uuid || newObject.id
                      col.onSendFileApi(id, newObject[col.name]).subscribe({
                          next: (response: ApiResponse) => {
                              if(response.status == ApiResponseStatus.Success) {
                                if(--count == 0) {
                                  if(snackbar != null) {
                                    snackbar.dismiss()  
                                  }       
                                  this.tableDefinition.onCreate(newObject, result, data)   
                                  if(this.tableDefinition.onCreateTextLoaded != null) {
                                    this.translateService.get(this.tableDefinition.onCreateTextLoaded()).subscribe((res: string) => 
                                      this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
                                  }
                                }
                              } else {
                                this.translateService.get(response.message).subscribe((res: string) =>  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
                              }
                          },
                          error: (error) => this.errorService.OnError.error(error)
                      })
                    }
                  })
                } else {
                  if(snackbar != null) {
                    snackbar.dismiss()  
                  }       
                  this.tableDefinition.onCreate(newObject, result, data)   
                  if(this.tableDefinition.onCreateTextLoaded != null) {
                    this.translateService.get(this.tableDefinition.onCreateTextLoaded()).subscribe((res: string) => 
                      this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
                  }
                }
              } else {
                if(snackbar != null) {
                  snackbar.dismiss()  
                }       
                this.translateService.get(response.message).subscribe((res: string) =>  
                  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
              }
            },
            error: (error) => this.errorService.OnError.error(error)
          })
        } else {
          this.tableDefinition.onCreate(newObject, result, data)   
        }     
      }
    })
  }

  onDelete(isAll: boolean): void {    
    var deleteCallback = (): void => {
      var list: number[] = []
      if(isAll) {
        this.dataSource.data.forEach(row => list.push(row.id || row.uuid))
      } else {                
        this.selectedRowIds.forEach(id => list.push(id))
      }
      this.selectedRowIds.clear()

      if(this.tableDefinition.onDeleteApi != null) {
        var snackbar = null
        
        if(this.tableDefinition.onDeleteTextLoading != null) {
          this.translateService.get(this.tableDefinition.onDeleteTextLoading()).subscribe((res: string) => 
            snackbar = this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top' }))
        }

        var count = list.length

        list.forEach(l => {
          this.tableDefinition.onDeleteApi(l).subscribe({
            next: (response: ApiResponse) => {                  
              if(response.status == ApiResponseStatus.Success) {     
                if(--count == 0) {
                  if(snackbar != null) {
                    snackbar.dismiss()  
                  } 
                  this.tableDefinition.onDelete(list)
                  if(this.tableDefinition.onDeleteTextLoaded != null) {
                    this.translateService.get(this.tableDefinition.onDeleteTextLoaded()).subscribe((res: string) => 
                      this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
                  }
                }  
              } else {
                if(snackbar != null) {
                  snackbar.dismiss()  
                } 
                this.translateService.get(response.message).subscribe((res: string) =>  
                  this.snackbar.open(res, '', { horizontalPosition: 'right', verticalPosition: 'top', duration: 5000 }))
              }
            },
            error: (error) => this.errorService.OnError.error(error)
          })
        })
        
      } else {
        this.tableDefinition.onDelete(list)
      }

    }

    var list: string[] = []
    if(isAll) {
      this.dataSource.data.forEach(row => {
        if(this.tableDefinition.listNameColumnFunction != null) {
          list.push(this.tableDefinition.listNameColumnFunction(row))
        } else {
          list.push(row[this.tableDefinition.listNameColumn])
        }
      })   
    } else {      
      this.getSelectedRows().forEach(row => {
        if(this.tableDefinition.listNameColumnFunction != null) {
          list.push(this.tableDefinition.listNameColumnFunction(row))
        } else {
          list.push(row[this.tableDefinition.listNameColumn])
        }
      })   
    }
    var title2 = this.tableDefinition.showTblnameInTitle ? this.tableDefinition.tblName : ''    
    this.openYesNoDialog('tbl_delete', title2, 'tbl_deleteText', list, 'btn_yes', 'btn_no', deleteCallback)
  }
    
  openYesNoDialog(title: string, title2: string, text: string, items: string[], btnTextYes: string, btnTextNo: string, actionYes: () => any): void {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true

    let dialogRef = this.dialog.open(DynamicDialogComponent, dialogConfig)
    dialogRef.componentInstance.title = title
    dialogRef.componentInstance.title2 = title2
    dialogRef.componentInstance.text = text
    dialogRef.componentInstance.listItems = items
    dialogRef.componentInstance.btnTextYes = btnTextYes
    dialogRef.componentInstance.btnTextNo = btnTextNo
    dialogRef.afterClosed().subscribe((result) => {
      if(result) actionYes()
    })
  }

  openMediaList(event, col, element) {
    event.stopPropagation()

    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true
    dialogConfig.width = "550px"

    let dialogRef = this.dialog.open(ConfigMedialistComponent, dialogConfig)
    dialogRef.componentInstance.title = 'mediaList'    
    dialogRef.componentInstance.title2 = this.tableDefinition.rowNameFunction != null ? this.tableDefinition.rowNameFunction(element) : this.tableDefinition.tblName
    dialogRef.componentInstance.setData(this.tableDefinition, element, element[col.name])    
  }
}
