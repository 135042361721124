<div class="eaton mat-typography" style="overflow-x:hidden;" fxFlexFill cdkScrollable>
<mat-toolbar color="primary" class="toolbar">
	<button mat-icon-button (click)="isExpanded = !isExpanded"><mat-icon>menu</mat-icon></button>
	<img src="assets/img/Logo.png" height="40px" />
	<h1 class="app-name px-4">{{ 'navigation_header' |translate}}</h1>
	<span class="spacer"></span>

    <!-- <button mat-button [matMenuTriggerFor]="menu">{{currentLanguage | translate}}</button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let langCode of languagesCodes" (click)="onSetLanguage(langCode)" >{{langCode | translate}}</button>
    </mat-menu>
    <button mat-icon-button  (click)="openSearch()">
        <mat-icon>search</mat-icon>
    </button> 

	<button mat-button [routerLink]="''"><mat-icon fontIcon="synch"></mat-icon></button> -->
</mat-toolbar>

<!-- <mat-toolbar class="search-block mat-elevation-z4" [class.active]="toggleSearch">
    <mat-toolbar-row style="padding: 0 5px;">
        <button class="search-icon" mat-icon-button disabled>
            <mat-icon>search</mat-icon>
        </button>

        <mat-form-field style="margin-bottom: -1.25em">
            <mat-label>{{ 'search_section' |translate}}</mat-label>
            <mat-select [(ngModel)]="searchSection">
                <mat-option *ngFor="let s of searchSections" [value]="s">{{'search_section_' + s |translate}}</mat-option>
            </mat-select>
        </mat-form-field>

        <input class="search-control" type="text" placeholder="{{ 'search_enterText' |translate}}" [(ngModel)]="searchText" #searchbar>
        <button mat-icon-button (click)="searchClose()">
            <mat-icon>close</mat-icon>
        </button> 
    </mat-toolbar-row>
</mat-toolbar> -->
</div>

<mat-sidenav-container class="container mw-100 p-0" autosize="">
	<mat-sidenav #sidenav class="sidenav" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
		<mat-nav-list style="position: relative; height: 93.2%;">
			<div class="py-2" [ngStyle]="{'background-color': (color$ | async)}">
				<div class="d-flex justify-content-left align-items-center" *ngIf="(loggedIn$ |async) && isExpanded">
					<div class="rounded-circle mx-2 p-1 d-flex justify-content-center align-items-center loginPersonIcon">
						<mat-icon>person</mat-icon>
					</div>
					<div class="ms-3">{{ (userDetails$ | async).firstname + ' ' + (userDetails$ | async).name }}</div>
				</div>
			</div>
			<app-sidemenu-button
				[isExpanded]="isExpanded"	
				*ngIf="loggedIn$ |async"
				[menu]="{
					routerLink: '/auth/logout',
					routerLinkActive: 'activeLink',
					icon: 'logout',
					name: 'sidenav_logOut',
					subMenu: []
				}
			"></app-sidemenu-button>			
			<hr>
			<!-- <app-sidemenu-button
				[isExpanded]="isExpanded"
				[menu]="{
					routerLink: '/',
					routerLinkActive: 'activeLink',
					icon: 'dashboard',
					name: 'sidenav_dashboard',
					subMenu: []
				}
			"></app-sidemenu-button> -->
				
			<!-- <app-sidemenu-button
				[isExpanded]="isExpanded"
				[menu]="{
					routerLink: '/customer',
					routerLinkActive: 'activeLink',
					icon: 'group',
					name: 'sidenav_customer',
					subMenu: []
				}
			"></app-sidemenu-button> -->
			<app-sidemenu-button
			[isExpanded]="isExpanded"
			[menu]="{
				routerLink: '/projects',
				routerLinkActive: 'activeLink',
				icon: 'topic',
				name: 'sidenav_projects',
				subMenu: []
			}
		"></app-sidemenu-button>
			<app-sidemenu-button
				[isExpanded]="isExpanded"
				[menu]="{
					routerLink: '/services',
					routerLinkActive: 'activeLink',
					icon: 'manage_accounts',
					name: 'sidenav_services',
					subMenu: []
				}
			"></app-sidemenu-button>
			<app-sidemenu-button
				[isExpanded]="isExpanded"
				[menu]="{
					routerLink: '/designer',
					routerLinkActive: 'activeLink',
					icon: 'draw',
					name: 'sidenav_designer',
					subMenu: []
				}
			"></app-sidemenu-button>
			<app-sidemenu-button
				[isExpanded]="isExpanded"
				[menu]="{
					routerLink: '/designerEditor',
					routerLinkActive: 'activeLink',
					icon: 'border_color',
					name: 'sidenav_designeEditor',
					subMenu: []
				}
			"></app-sidemenu-button>
			<app-sidemenu-button
				[isExpanded]="isExpanded"
				[menu]="{
					routerLink: '/settings',
					routerLinkActive: 'activeLink',
					icon: 'settings',
					name: 'sidenav_settings',
					subMenu: []
				}
			"></app-sidemenu-button>
				
			<app-sidemenu-button
				[isExpanded]="isExpanded"
				[menu]="{
					routerLink: '/info',
					routerLinkActive: 'activeLink',
					icon: 'info',
					name: 'sidenav_info',
					subMenu: []
				}
			"></app-sidemenu-button>
			
			<div style="position: absolute; 
                    bottom: 0; 
                    width: 100%; 
                    height: 60px;">
					
			<hr *ngIf="isExpanded">

			<div class="d-flex justify-content-center flex-column align-items-center" *ngIf="isExpanded">
				<div style="color: gray; font-size: 11px;">v1.1 © 2024 Kronos Assets GmbH</div>
			</div>
  </div>


			<!--
				<app-sidemenu-button
				[routerLink]="''"
				[routerLinkActive]="'activeLink'"
				[icon]="'home'"
				[name]="'Login'"
				></app-sidemenu-button>
			<app-sidemenu-button
				[routerLink]="''"
				[routerLinkActive]="'activeLink'"
				[icon]="'home'"
				[name]="'Logout'"
				></app-sidemenu-button>
			<app-sidemenu-button
				[routerLink]="''"
				[routerLinkActive]="'activeLink'"
				[icon]="'home'"
				[name]="'Dashboard'"
				></app-sidemenu-button>
			<app-sidemenu-button
				[routerLink]="''"
				[routerLinkActive]="'activeLink'"
				[icon]="'home'"
				[name]="'Datenerfassung'"
				></app-sidemenu-button>


			<a mat-list-item [routerLink]="'/dashboard/departments'" routerLinkActive="activeLink" autosize>
				<mat-icon mat-list-icon class="icon">home</mat-icon>
				<span class="full-width mx-2" *ngIf="isExpanded || isShowing">Abteilungen</span>
				<span class="mx-1"></span>
			</a>
			<a mat-list-item [routerLink]="'/dashboard/sections'" routerLinkActive="activeLink" autosize>
				<mat-icon mat-list-icon class="icon">view_compact</mat-icon>
				<span class="full-width mx-2" *ngIf="isExpanded || isShowing">Bereiche</span>
				<span class="mx-1"></span>
			</a>
			<a mat-list-item [routerLink]="'/dashboard/questions'" routerLinkActive="activeLink" autosize>
				<mat-icon mat-list-icon class="icon">question_mark</mat-icon>
				<span class="full-width mx-2" *ngIf="isExpanded || isShowing">Fragen</span>
				<span class="mx-1"></span>
			</a>
			<a mat-list-item [routerLink]="'/dashboard/importExport'" routerLinkActive="activeLink" autosize>
				<mat-icon mat-list-icon class="icon">sync_alt</mat-icon>
				<span class="full-width mx-2" *ngIf="isExpanded || isShowing">Import/Export</span>
				<span class="mx-1"></span>
			</a>
			<a mat-list-item [routerLink]="'/dashboard/software'" routerLinkActive="activeLink" autosize>
				<mat-icon mat-list-icon class="icon">info</mat-icon>
				<span class="full-width mx-2" *ngIf="isExpanded || isShowing">Software</span>
				<span class="mx-1"></span>
			</a>			-->
		</mat-nav-list>
		<mat-nav-list>
		</mat-nav-list>
	</mat-sidenav>

	<div class=" p-2">
		<ng-content></ng-content>
	</div>
</mat-sidenav-container>


