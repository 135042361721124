<h2 mat-dialog-title>
    {{ 'tblInfoHeader' | translate}} {{ tableDef.infoDialogTitle != null ? data[tableDef.infoDialogTitle] : tableDef.infoDialogTitleFunction(data)}}
</h2>

<mat-dialog-content> 
    <div class="row" *ngFor="let col of getDisplayCols()">
        <div class="col">
            <strong>{{ tableDef.getColumnTitle(col) | translate }}:</strong>
        </div>
        <div class="col">
            <span [innerHTML]="data[col.name] | dynamicPipe: col.pipe:combinePipeArgs(null, col.pipeArgs)"></span>
        </div>
    </div>   
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" mat-dialog-close>{{ 'btn_close' | translate}}</button>
</mat-dialog-actions>