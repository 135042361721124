import { Component } from '@angular/core';
import * as globals from 'src/app/_shared/global';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent {
  version = globals.version



  constructor() {
   
    
  }

}
