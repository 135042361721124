
<br>
<ng-template errorBox></ng-template>

<mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">menu</mat-icon>
            <span class="mx-1">{{'settingsHeaderGeneral' | translate }}</span>
        </ng-template>         
        <br />  
        
        <app-config-table
            #tblSetting
            [tableDefinition]="cfgTableSetting"
            [tableData]="cfgTableSettingData"
        >
        </app-config-table>
    </mat-tab>    

   
     
    <mat-tab> 
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">flag</mat-icon>
            <span class="mx-1">{{'settingsHeaderCountries' | translate }}</span>
        </ng-template>     
        <br />   
        
        <app-config-table
            #tblCountry
            [tableDefinition]="cfgTableCountry"
            [tableData]="cfgTableCountryData"
        >
        </app-config-table>
    </mat-tab>
    <mat-tab>      
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">translate</mat-icon>
            <span class="mx-1">{{'settingsHeaderText' | translate }}</span>
        </ng-template> 
        <br />   
        
        <app-config-table
            #tblTranslation
            [tableDefinition]="cfgTableTranslation"
            [tableData]="cfgTableTranslationData"
        >
        </app-config-table>
    </mat-tab>
</mat-tab-group>     
<br />   
<br />
<br />
<br />