import { Component, EventEmitter, Output } from '@angular/core';
import { IConfigFormElement } from '../../interface/IConfigFormElement';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ConfigTableDef, ConfigTableColDef, ConfigTableColType } from '../config-table/config-table.component';

@Component({
  selector: 'app-config-dynamic-form-file',
  templateUrl: './config-dynamic-form-file.component.html',
  styleUrls: ['./config-dynamic-form-file.component.scss']
})
export class ConfigDynamicFormFileComponent implements IConfigFormElement {
  public title = ''
  public width = '100'

  formData: FormData = null
  
  form = this._formBuilder.group({
    file: [''],
  })
  
  valueChange = new EventEmitter<FormData>()

  constructor(private _formBuilder: FormBuilder,
  )
  {
    
  }

  setColData(tableData: ConfigTableDef<any>, col: ConfigTableColDef) {
    this.title = tableData.getColumnTitle(col)
  }
  
  setValue(value) {
    this.form.controls.file.setValue(value != null && value != '' ? environment.apiPath + '/' + value : '')    
  }
  
  selectFile(event) {
    const file:File = event.target.files[0];
    if (file) {
      this.formData = new FormData()
      this.formData.append("file", file)
             
      const reader = new FileReader()
      reader.onload = (e: any) => {
        this.form.controls.file.setValue(e.target.result)    
        this.valueChange.emit(this.formData)
      }
      reader.readAsDataURL(file)          
    }    
  }

  removeFile(){
    this.formData = new FormData()
    this.form.controls.file.setValue('')
    this.valueChange.emit(this.formData)            
  }

  setErrorMessage(msg: string) {
    //this.errorMsg = msg
  }

  onChangeValue(): void {
    this.valueChange.emit(this.formData)
  }
}
