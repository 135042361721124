<div class="my-2">  
    
    <mat-form-field class="d-flex" appearance="fill" style="margin-bottom: -1.25em">
        <mat-label>{{ label | translate }}</mat-label>
        <mat-select [(ngModel)]="value" [multiple]="multiple" (ngModelChange)="onChangeValue()">        
            <mat-option *ngFor="let item of items" [value]="item.key">{{ item.value | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    
    <div class="ms-2" style="font-size: 14px;">{{ hint | translate}}</div>
    <div class="ms-2" style="font-size: 14px; color: red;">{{ errorMsg | translate}}</div>
</div>

