import { Component, OnInit } from '@angular/core';
import { DataUserManagementService } from './_services/data.usermanagement.service';
import { StaticFunctions } from './_services/static.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomTranslateService } from './_services/customtranslate.service';
import { Router } from '@angular/router';
import { ErrorService } from './_services/error.service';
import { DataSettingService } from './_services/data.settings.service';
import { DataDynamicContentService } from './_services/data.dynamicContent.service';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  //0: wait, 1: loading, 2: finish ok 3: finish nok
  loadingAuth = 0
  loadingDataSettings = 0
  loadingDataUsermanagement = 0
  loadingSettings = 0
  loadingDynamicContent = 0

  isNotAuth = false
  isLoaded = false
  error = ""
  errorShow$ = new BehaviorSubject<string>("")
  loggedIn$: Observable<boolean>
  socket: Socket = null
  
  constructor(
    private dataUsermanagementService: DataUserManagementService,
    private dataSettingsService: DataSettingService,
    private dataDynamicContentService: DataDynamicContentService,
    private staticService: StaticFunctions,
    private translateService: CustomTranslateService,
    private router: Router,
    private errorService: ErrorService,
    ) 
    { 
      dataUsermanagementService.OnCustomersChanged.subscribe({ error: (e: Error) => this.AddError(e) })
      dataSettingsService.OnCountriesChanged.subscribe({ error: (e: Error) => this.AddError(e) })
      dataDynamicContentService.OnMenuChanged.subscribe({ error: (e: Error) => this.AddError(e) })
      dataDynamicContentService.OnMenuEntryChanged.subscribe({ error: (e: Error) => this.AddError(e) })
      dataDynamicContentService.OnDynamicPageChanged.subscribe({ error: (e: Error) => this.AddError(e) })
    
      errorService.OnError.subscribe({ error: (e: Error) => this.AddError(e) })
      
      this.loggedIn$ = this.dataUsermanagementService.LoginState
      this.translateService.loadTranslations()      
    }

  private AddError(error) {
    if(error.status == 409) return    
    console.error(error)
    this.error += error.name + ": " + error.message + "<br>" + (error.error?.error != null ? error.error.error + "<br><br>" : "")
        
    this.errorShow$.next(this.error)
  }

  private initSubscriptions(){
    this.dataUsermanagementService.OnAuthLoadedFinished.subscribe({
      next: (response: boolean) => {
        if(this.loadingAuth == 1) {          
          if(response) {
            this.loadingAuth = 2
            this.loadDataUserManagement()
          } else {          
            this.loadingAuth = 3 
            this.isNotAuth = true
            this.router.navigate(['auth/login'])
          }
        }
      },
      error: (error) => {
        this.loadingAuth = 3
        this.AddError(error)
      }
    })
    
    this.dataUsermanagementService.OnDataLoadedChanged.subscribe({
      next: (response) => {
        if(response) {
          this.loadingDataUsermanagement = 2
          this.loadDataSettings()
        } else {          
          this.loadingDataUsermanagement = 3
        }
      },
      error: (error) => {
        this.loadingDataUsermanagement = 3
        this.AddError(error)
      }
    })

    this.dataSettingsService.OnDataLoadedChanged.subscribe({
      next: (response: boolean) => {
        if(this.loadingSettings == 1) {
          if(response) {
            this.loadingSettings = 2
            this.loadDataDynamicContent()
          } else {          
            this.loadingSettings = 3 
          }
        }
      },
      error: (error) => {
        this.loadingSettings = 3
        this.AddError(error)
      }
    })

    this.dataDynamicContentService.OnDataLoadedChanged.subscribe({
      next: (response: boolean) => {
        if(this.loadingDynamicContent == 1) {
          if(response) {
            this.loadingDynamicContent = 2
            this.loadSocketIo()            
          } else {          
            this.loadingDynamicContent = 3 
          }
        }
      },
      error: (error) => {
        this.loadingDynamicContent = 3
        this.AddError(error)
      }
    })
    
   
    
   
  }

  private loadAuth(){
    this.loadingAuth = 1
    if(!this.dataUsermanagementService.loadAuth()) {            
      //this.router.navigate(['auth/login'])
    }
  }

  private loadDataUserManagement(){
    this.loadingDataUsermanagement = 1
    this.dataUsermanagementService.loadData()
  }

  private loadDataSettings(){
    this.loadingSettings = 1
    this.dataSettingsService.loadData()
  }

  private loadDataDynamicContent(){
    this.loadingDynamicContent = 1
    this.dataDynamicContentService.loadData()
  }

  private loadSocketIo() {    
    //this.socket = io(environment.ioPath)       
    // this.socket.on('updateBasket', () => this.dataCheckoutService.synchShoppingBasket())    
  }

  ngOnInit(): void {    
    this.initSubscriptions()
    
    this.loadAuth() //start

    this.dataUsermanagementService.OnManuelLogin.subscribe({
      next: (response) => {
        this.isNotAuth = false
        this.loadingAuth = 0
        this.loadingDataUsermanagement = 0
        this.loadingSettings = 0
        this.loadingDynamicContent = 0

        this.dataUsermanagementService.ResetLoadCounter()
        this.dataSettingsService.ResetLoadCounter()
        this.dataDynamicContentService.ResetLoadCounter()
        
        this.loadAuth()
      }
    })
  }

  isLoadingFinish(): boolean {
    this.isLoaded = 
    this.isNotAuth || (
      this.loadingAuth == 2 
      && this.loadingDataUsermanagement == 2 
      && this.loadingSettings == 2 
    )
    return this.isLoaded
  }

}
