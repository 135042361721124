import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";



@Injectable()
export class CustomTranslateService {

    languagesCodes: string[] = ["de", "en"]
    currentLanguage: string = this.languagesCodes[0] 

    constructor(
        private translate: TranslateService,
      ) { 
        
      }

    loadTranslations(){
      this.translate.use(this.currentLanguage)

    }
}