import { Component, Input, OnInit } from '@angular/core';

export class ISubMenu{
  name: string = 'default'
  routerLink: string = ''
  routerLinkActive: string = ''
  icon: string = ''
  subMenu: ISubMenu[] = []
}

@Component({
  selector: 'app-sidemenu-button',
  templateUrl: './sidemenu-button.component.html',
  styleUrls: ['./sidemenu-button.component.scss']
})
export class SidemenuButtonComponent implements OnInit {
  @Input() menu: ISubMenu
  @Input() isExpanded: boolean = false
  
  showSubMenu: boolean = false

  constructor() {

  }

  ngOnInit() {
        
  }


  //showSubSubMenu: boolean = false;

}
