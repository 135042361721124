import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, output, ViewEncapsulation } from '@angular/core';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';
import {
  CodeEditorComponent,
  CodeEditorModule,
  CodeEditorService,
  CodeModel,
  CodeModelChangedEvent
} from '@ngstack/code-editor';
import { debounceTime, Observable } from 'rxjs';
import { editor } from 'monaco-editor';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RestApiService } from 'src/app/_services/restApi.service';
import { ApiResponse, ApiResponseStatus } from 'src/app/_shared/classes/ApiResponse';
import { ErrorService } from 'src/app/_services/error.service';
import { DataDynamicContentService } from 'src/app/_services/data.dynamicContent.service';
import { DynamicPage } from 'src/app/_shared/classes/DynamicContent';

@Component({
  selector: 'app-designer-editor-code',
  templateUrl: './designer-editor-code.component.html',
  styleUrl: './designer-editor-code.component.scss'
})
export class DesignerEditorCodeComponent implements OnInit {
  @Input() language: string = 'html'
  @Output() onCodeChange: EventEmitter<string> = new EventEmitter()

  theme = 'vs';
  model: CodeModel = null

  options: editor.IStandaloneEditorConstructionOptions = {
    contextmenu: true,
    minimap: {
      enabled: true
    },
    automaticLayout: true,
  };

  constructor(editorService: CodeEditorService,
    private restApiService: RestApiService,
    private errorService: ErrorService,
    private dataDynamic: DataDynamicContentService,
  ) {
    
    

  }

  ngOnInit(): void {

    //this.dataDynamic.OnDynamicPageChanged.subscribe(() => this.setPageCss())
    
  }

  setCode(code: string) {
    this.model = {
      language: this.language,
      uri: 'main.' + this.language,
      value: code
    }
  }



  // setPageCss() {
  //   if(this.dataDynamic.pages.length > 0) {

  //     var pageHome = this.dataDynamic.pages.find(p => p.id == 1)
    
  //     this.model = {
  //       language: this.language,
  //       uri: 'main.' + this.language,
  //       value: pageHome.cssStyle
  //     }

  //   }
  // }

  // onEditorLoaded(editor: CodeEditorComponent) {
  //   console.log('loaded', editor);
  // }

  private code: string = ''
  onCodeChanged(value) {
    this.code = value
    this.onCodeChange.emit(value)
  }

  
  
}
