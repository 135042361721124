import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent {
  @Input() title: string
  @Input() text: string
  @Input() listItems: string[]
  @Input() btnTextYes: string
  @Input() btnTextNo: string

  constructor(
    private dialogRef: MatDialogRef<YesNoDialogComponent>
  ) { }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
