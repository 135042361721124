<div class="my-2">
    <mat-checkbox
        [labelPosition]="'after'"
        [(ngModel)]="value"
        [disabled]="disabled"
        (change)="onChangeValue()">        
        <span [innerHTML]="label | translate"></span>
    </mat-checkbox>
    <span class="ms-2" style="font-size: 14px;">{{ hint | translate}}</span>
    <div class="ms-2" style="font-size: 14px; color: red;">{{ errorMsg | translate}}</div>
</div>