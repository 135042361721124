<br />

<div class="row">
    <div class="col d-flex justify-content-md-center">
        <img src="assets/img/LogoInfo.png" height="200px"/>
    </div>
</div>
<br />

<div class="row">
    <div class="col d-flex justify-content-md-center"> 
        <p>Sofware: KronosAssets Administration<br /><br />
            Version: {{version}}<br /><br /><br />
            Company: <a href="https://kronosassets.com/" target="_blank">Kronos Assets GmbH</a><br /><br />
            <!-- Telefon: - <br /><br /> -->
            Website: <a href="https://kronosassets.com/" target="_blank">www.kronosassets.com</a></p> 
    </div>
</div>
<br />
<br />
<br />




<br />
<br />
<br />
<br />
<br />
<br />