import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { IConfigFormElement } from '../../interface/IConfigFormElement';
import { ConfigTableDef, ConfigTableColDef } from '../config-table/config-table.component';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-config-dynamic-form-date',
  templateUrl: './config-dynamic-form-date.component.html',
  styleUrls: ['./config-dynamic-form-date.component.scss']
})
export class ConfigDynamicFormDateComponent implements IConfigFormElement {
  @ViewChild('picker') picker: any

  public title = ''
  public disabled = false
  public showSpinners = true
  public showSeconds = false
  public touchUi = false
  public enableMeridian = false
  public minDate: Date
  public maxDate: Date
  public stepHour = 1
  public stepMinute = 1
  public stepSecond = 1
  public color: ThemePalette = 'primary'
  public disableMinute = false
  public hideTime = false

  public isDarkTheme = false
  
  public stepHours = [1, 2, 3, 4, 5]
  public stepMinutes = [1, 5, 10, 15, 20, 25]
  public stepSeconds = [1, 5, 10, 15, 20, 25]

  public dateControl = new FormControl(null);

  @Output() valueChange = new EventEmitter<Date>()

  constructor(private helperService: HelperService) {
    this.isDarkTheme = helperService.isDarkTheme
  }
  
  setColData(tableData: ConfigTableDef<any>, col: ConfigTableColDef): void {
    this.title = tableData.getColumnTitle(col)
    this.showSeconds = col.dynamicData?.showSeconds
  }

  setErrorMessage(msg: string): void {
    
  }

  onChangeValue(): void {        
    if(this.dateControl.value != null) this.valueChange.emit(this.dateControl.value)
  }

  onResetDate() {
    this.dateControl.setValue(null)
    this.valueChange.emit(null)
  }

  setValue(date: Date) {
    if(date != null) {
      this.dateControl.setValue(date)
    }
  }
}
