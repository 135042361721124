import { EventEmitter } from "@angular/core"

export class DataParentService {

    private loadCounter: number = 0
    private currentLoadCounter: number = 0

    public OnDataLoadedChanged : EventEmitter<boolean> = new EventEmitter()
    public loadedFinished: boolean = false

    constructor(steps?: number) { 
        if(steps != null) this.ResetLoadCounter(steps)
    }

    public ResetLoadCounter(steps: number = this.loadCounter) {
        this.loadCounter = steps
        this.currentLoadCounter = 0
    }
    

    public SetNextLoadStep() {
        this.currentLoadCounter++
        if(this.currentLoadCounter == this.loadCounter){
            this.OnLoadFinish()
        }
    }

    public OnLoadFinish(){
        this.OnDataLoadedChanged.next(true)
        this.loadedFinished = true
    }

    public AddLoadError(error) {        
        this.OnDataLoadedChanged.error(error)
    }
}