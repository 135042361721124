<mat-card class="my-2">
    <mat-card-header>
      <mat-card-title>{{title|translate}}</mat-card-title>
      <mat-card-subtitle>{{description|translate}}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="mt-2">
        <ng-content></ng-content>
        <div [innerHTML]="content"></div>
    </mat-card-content>
    
    <!--
    <mat-card-footer class="py-2 px-2">
        <button mat-raised-button color="primary" (click)="onClickSave.emit()">{{'btn_save'|translate}}</button>
    </mat-card-footer>
    -->
</mat-card>