<h1 mat-dialog-title>{{title | translate}} {{title2 | translate}}</h1>
<div mat-dialog-content>
    
  <button type="button" color="primary" class="my-auto" mat-raised-button (click)="fileInput1.click()">{{ 'choose_pictures' | translate }}</button>
  <input hidden (change)="selectFiles($event)" #fileInput1 type="file" id="file" [multiple]="true">
  <br />
  <br />

  <div class="row my-2" *ngFor="let media of mediaListEntries">
    <div class="col">
      <img [src]="media.picture" [width]="200" />
    </div>
    <div class="col">
      <span>{{ media.fileName }}</span>
      <br />
      <span>{{ media.widthHeight }}</span>
      <br />
      <br />
      <button type="button" mat-icon-button class="mx-1 removeBtn" (click)="downloadFile(media)"><mat-icon>download</mat-icon></button>
      <button type="button" mat-icon-button class="mx-1 removeBtn" (click)="removeFile(media)"><mat-icon>delete</mat-icon></button>
      <button type="button" mat-icon-button class="mx-1 removeBtn" (click)="fileMoveUp(media)"><mat-icon>arrow_drop_up</mat-icon></button>
      <button type="button" mat-icon-button class="mx-1 removeBtn" (click)="fileMoveDown(media)"><mat-icon>arrow_drop_down</mat-icon></button>
    </div>
  </div>


</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onClickClose()">{{ 'btn_close' | translate }}</button>
</div>
