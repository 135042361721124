
<br>
<ng-template errorBox></ng-template>

<mat-tab-group>
    <!-- <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">tune</mat-icon>
            <span class="mx-1">{{'pageDesignerTitleSettings' | translate }}</span>
        </ng-template>         
        <br />  
      
        
        
            
    </mat-tab> -->
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">menu</mat-icon>
            <span class="mx-1">{{'pageDesignerTitleMenu' | translate }}</span>
        </ng-template>         
        <br />  
      
        <app-config-table
            #tblMenu
            [tableDefinition]="cfgTableMenu"
            [tableData]="cfgTableMenuData"
        >
            
        </app-config-table>
    </mat-tab>
    <mat-tab> 
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">list</mat-icon>
            <span class="mx-1">{{'pageDesignerTitleMenuEntries' | translate }}</span>
        </ng-template>     
        <br />   
      
        <app-config-table
            #tblMenuEntries
            [tableDefinition]="cfgTableMenuEntries"
            [tableData]="cfgTableMenuEntriesData"
        >
    </app-config-table>
        
    </mat-tab>
    <mat-tab>      
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">description</mat-icon>
            <span class="mx-1">{{'pageDesignerTitlePages' | translate }}</span>
        </ng-template> 
        <br />   
      
        <app-config-table
            #tblPages
            [tableDefinition]="cfgTablePages"
            [tableData]="cfgTablePagesData"
        > 
        </app-config-table>
        
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">summarize</mat-icon>
            <span class="mx-1">{{'pageDesignerTitleTemplates' | translate }}</span>
        </ng-template>         
        <br />  
           
        
        <app-config-table
            #tblTemplates
            [tableDefinition]="cfgTemplates"
            [tableData]="cfgTemplatesData"
        >
        </app-config-table>
            
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">image</mat-icon>
            <span class="mx-1">{{'pageDesignerTitleMedias' | translate }}</span>
        </ng-template>         
        <br />  
           
        
        <app-config-table
            #tblMedias
            [tableDefinition]="cfgMedias"
            [tableData]="cfgMediasData"
        >
        </app-config-table>
            
    </mat-tab>
</mat-tab-group>     
<br />   
<br />
<br />
<br />