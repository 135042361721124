import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConfigTableDef, ConfigTableColDef, ConfigTableColType, ConfigTableComponent } from 'src/app/_khLib/components/config-table/config-table.component';
import { DynamicPipeType } from 'src/app/_pipes/dynamic.pipe';
import { DataSettingService } from 'src/app/_services/data.settings.service';
import { ErrorService } from 'src/app/_services/error.service';
import { RestApiService } from 'src/app/_services/restApi.service';
import { ApiResponse } from 'src/app/_shared/classes/ApiResponse';
import { Project, Service } from 'src/app/_shared/classes/Settings';

@Component({
  selector: 'app-services',
  
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent implements OnInit {
  cfgTable = new ConfigTableDef<Service>(Service, {
    tblName: 'service',
    showTblnameInTitle: true,
    showDetailDialog: false,
    //infoDialogTitleFunction: (e) => { return e.name},
    cols: [
      new ConfigTableColDef({name: 'id',          type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'title',         type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'description',         type: ConfigTableColType.TextEditor, showInTable: true, filterable: false, pipe: DynamicPipeType.LimitText}),
      new ConfigTableColDef({name: 'image',         type: ConfigTableColType.File, showInTable: true, filterable: false, pipe: DynamicPipeType.Image, onSendFileApi: (i,e) => this.restApiService.createServiceImage(i,e)}),   
      new ConfigTableColDef({name: 'orderId',         type: ConfigTableColType.Number, showInTable: true, pipe: DynamicPipeType.None}),   
    ], 
    showInlineEditButton: true,
    menuButtons: [

    ],   
    //createBeforeEditData: true,
    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createService(c),
    onCreate: (object, result) => this.dataSettings.synchService(),
    onCreateTextLoading: (): string => 'serviceInfoCreate',
    onCreateTextLoaded: (): string => 'serviceInfoCreated',
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.updateService(c),
    onEdit: (object, result) => this.dataSettings.synchService(),
    onEditTextLoading: (): string => 'serviceInfoEdit',
    onEditTextLoaded: (): string => 'serviceInfoEdited',

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteService(ids),
    onDelete: (object, result) => this.dataSettings.synchService(),
    onDeleteTextLoading: (): string => 'serviceInfoDelete',
    onDeleteTextLoaded: (): string => 'serviceInfoDeleted',

    onSynch: () => this.dataSettings.synchService(),

    listNameColumnFunction: (d) => d.title,
  })
  cfgTableData = this.dataSettings.services
  @ViewChild('tbl') tbl: ConfigTableComponent

  constructor(private settingsData: DataSettingService,
    private restApiService: RestApiService,
    private snackbar: MatSnackBar,
    private dataSettings: DataSettingService,
    private translateService: TranslateService,
    private errorService: ErrorService) {
      
  }

  ngOnInit(): void {
    this.dataSettings.OnServiceChanged.subscribe((response) => this.tbl.refreshData(response) )
  }  

}
