import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './public/dashboard/dashboard.component';
import { InfoComponent } from './public/info/info.component';
import { SettingsComponent } from './public/settings/settings.component';
import { AuthLoginComponent } from './authentification/auth-login/auth-login.component';
import { AuthLogoutComponent } from './authentification/auth-logout/auth-logout.component';
import { DataUserManagementService } from './_services/data.usermanagement.service';
import { CustomerComponent } from './public/customer/customer.component';
import { DesignerComponent } from './public/designer/designer.component';
import { DesignerEditorComponent } from './public/designer-editor/designer-editor.component';
import { ProjectsComponent } from './public/projects/projects.component';
import { ServicesComponent } from './public/services/services.component';
const routes: Routes = [
  { 
    path: '', component: DashboardComponent,
    redirectTo: '',
    pathMatch: 'full',
    canActivate: [DataUserManagementService] 
  },
  { path: 'auth/login', component: AuthLoginComponent },
  // { path: 'auth/register', component: AuthRegisterComponent },
  { path: 'auth/logout', component: AuthLogoutComponent },
  
  { path: 'projects', component: ProjectsComponent},
  { path: 'services', component: ServicesComponent},
  { path: 'customer', component: CustomerComponent},
  { path: 'designer', component: DesignerComponent},
  { path: 'designerEditor', component: DesignerEditorComponent},
  { path: 'settings', component: SettingsComponent},
  { path: 'info', component: InfoComponent},  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
