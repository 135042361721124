<h1 mat-dialog-title>{{title | translate}}</h1>
<div mat-dialog-content>
  <p>{{text | translate}}</p>
  <ul *ngIf="listItems.length > 0">
    <li *ngFor="let item of listItems">{{item}}</li>
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onNoClick()">{{ btnTextNo | translate }}</button>
  <button mat-button (click)="onYesClick()">{{ btnTextYes | translate }}</button>
</div>
