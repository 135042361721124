import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataSettingService } from 'src/app/_services/data.settings.service';
import { SettingCountry, Translation } from 'src/app/_shared/classes/Settings';
import { ConfigDynamicFormTextEditorComponent } from '../config-dynamic-form-text-editor/config-dynamic-form-text-editor.component';
import { RestApiService } from 'src/app/_services/restApi.service';
import { DataUserManagementService } from 'src/app/_services/data.usermanagement.service';
import { ApiResponse } from 'src/app/_shared/classes/ApiResponse';
import { ErrorService } from 'src/app/_services/error.service';
import { StaticFunctions } from 'src/app/_services/static.service';

class TextChange {
  country: string
  text: string
}

@Component({
  selector: 'app-config-translate-editor',
  templateUrl: './config-translate-editor.component.html',
  styleUrls: ['./config-translate-editor.component.scss']
})
export class ConfigTranslateEditorComponent implements OnInit {

  translateKey: string
  countrySelect: string = 'DEU'
  countries: SettingCountry[] = []
  translations: Translation[] = []
  textChanges: TextChange[] = []

  preview: string = ''
  isSaving: boolean = false
  
  @ViewChild('editor', { static: true, read: ConfigDynamicFormTextEditorComponent }) editor: ConfigDynamicFormTextEditorComponent

  constructor(
    private dialogRef: MatDialogRef<ConfigTranslateEditorComponent>,
    private dataSettings: DataSettingService,
    private restApiService: RestApiService,
    private authData: DataUserManagementService,
    private errorService: ErrorService,
    private staticFunctions: StaticFunctions,
  ) {



  }

  ngOnInit(): void {
    this.countries = this.dataSettings.countries

    this.editor.valueChange.subscribe({
        next: (response: string) => {
          var textChange = this.textChanges.find(tc => tc.country == this.countrySelect)
          if(textChange != null) {
            textChange.text = response
            this.setPreview(response)
          } else {
            var newTc = new TextChange()
            newTc.country = this.countrySelect
            newTc.text = response
            this.textChanges.push(newTc)
          }
        }
    })
  }

  setPreview(prevRaw: string) {
    prevRaw = this.staticFunctions.replaceDynamicMedia(prevRaw)
    this.preview = prevRaw
  }

  setData(translateKey: string){
    this.translateKey = translateKey
    this.translations = this.dataSettings.translations.filter(trans => trans.key == translateKey)
    this.setTextByCountry(this.countrySelect)
  }

  onChangeCountry(event) {
    this.setTextByCountry(event.value)
  }

  setTextByCountry(countryId: string) {
    var country = this.dataSettings.countries.find(c => c.id == countryId)
    var translation = this.translations.find(trans => trans.country == country.id)
    var valueToSet = translation != null ? translation.text : ''
    this.editor.setValue(valueToSet)
    this.setPreview(valueToSet)
  }

  save() {
    if(this.textChanges.length == 0)
    {
      this.dialogRef.close()
      return
    }

    if(this.translateKey == null) 
    {
      console.warn('Warning: Translations not saved. TranslateKey is null!')
      this.dialogRef.close()
      return
    }

    this.isSaving = true
    var countSave = 0
    for (let i = 0; i < this.textChanges.length; i++) {
      const textChange = this.textChanges[i]            
      var translation = new Translation({
        customerUuid: this.authData.LastUserDetails.uuid,
        key: this.translateKey,
        country: textChange.country,
        text: textChange.text,
      })
      
      this.restApiService.createTranslations(translation).subscribe({
          next: (response: ApiResponse) => {
            if(++countSave == this.textChanges.length) {
              this.dataSettings.synchTranslations()
              this.dialogRef.close()
            }
          },
          error: (error) => this.errorService.OnError.error(error)
      })
    }
  }

  close() {
    this.dialogRef.close()
  }
}
