import { EventEmitter, Injectable } from "@angular/core"
import { DataDynamicContentService } from "./data.dynamicContent.service"
import { environment } from "src/environments/environment"

@Injectable()
export class StaticFunctions {

    constructor(
        private dataDynamic: DataDynamicContentService,
    ) {

    }
   
    convertTimeToArray(time: number): number[] {
        var minutes = time % 100
        var hours = (time - minutes) / 100
        return [hours, minutes, 0]
    }

    convertDateToTimeNumber(date: any) {
        return date._d.getHours() * 100 + date._d.getMinutes()
    }

    replaceDynamicMedia(content: string): string {
        while(true) {
            var s = content.indexOf('[media=\'')
            if(s == -1) break
            
            var e = content.indexOf('\']', s+1)
      
            var mediaStr = content.substring(s, e+2)
            var mediaName = content.substring(s+8, e)
            
            var media = this.dataDynamic.medias.find(m => m.name == mediaName)

            var replaceUrl = ''
            if(media != null) {
                replaceUrl = environment.apiPath + '/' + media.url
            }

            content = content.replace(mediaStr, replaceUrl)
            
        }

        return content
    }

}