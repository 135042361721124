import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfigFormElement } from '../../interface/IConfigFormElement';
import { ConfigTableDef, ConfigTableColDef, ConfigTableColType, ConfigTableDropDownEntry } from '../config-table/config-table.component';
import { ConfigTableColSelectionType, HelperService } from 'src/app/_services/helper.service';


// export class ConfigDynamicFormSelectionKeyValue {
//   key: any
//   value: any

//   public constructor(init?:Partial<ConfigDynamicFormSelectionKeyValue>) {
//     Object.assign(this, init);
//   }
// }

@Component({
  selector: 'app-config-dynamic-form-selection',
  templateUrl: './config-dynamic-form-selection.component.html',
  styleUrls: ['./config-dynamic-form-selection.component.scss']
})
export class ConfigDynamicFormSelectionComponent implements IConfigFormElement {
  @Input() label: string = ''
  @Input() hint: string = ''
  @Input() value: any
  @Input() items: ConfigTableDropDownEntry[] = []
  @Input() multiple: boolean = false
  @Input() activeItems: boolean = false
  @Input() errorMsg: string = ''
  @Input() colDef: ConfigTableColDef

  @Output() valueChange = new EventEmitter<any>()

  constructor(
    private helperService: HelperService,
  ) {}

  setColData(tableData: ConfigTableDef<any>, col: ConfigTableColDef): void {
    this.label = tableData.getColumnTitle(col)
    this.items = this.helperService.getDropDownValues(col.selectionType)
    this.colDef = col
    this.multiple = col.type == ConfigTableColType.SelectionMulti
  }

  setValue(value: any): void {    
    if(this.colDef.selectionType == ConfigTableColSelectionType.Bool) {      
      this.value = value ? 'true': 'false'
    } else {
      if(this.multiple) {
        if(value != null) {
          var strValues = value.toString().split(';')
          this.value = []
          strValues.forEach(strVal => this.value.push(parseInt(strVal)))
        }
      } else {
        this.value = value
      }
    }    
  }

  setErrorMessage(msg: string): void {
    this.errorMsg = msg
  }
  
  onChangeValue(): void {    
    this.valueChange.emit(this.multiple && this.value != null ? this.value.join(';') : this.value)
  }
}
