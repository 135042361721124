import {  Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";


@Injectable()
export class JwtService {
    private jwtToken: string
    private decodedToken: { [key: string]: string };

    setToken(token:string){
        this.jwtToken = token
    }

    decodeToken() {
      if (this.jwtToken) {
          this.decodedToken = jwt_decode(this.jwtToken);
      }
    }

    getDecodeToken() {
      return jwt_decode(this.jwtToken);
    }
  
    getExpiryTime(): number {
      this.decodeToken();
      return this.decodedToken ? Number(this.decodedToken["exp"]) : null
    }

    isTokenExpired(): boolean {
      const expiryTime: number = this.getExpiryTime()
      
      if (expiryTime) {
        return (expiryTime - (new Date()).getTime()) < 5000
      } else {
        return true
      }
    }
}