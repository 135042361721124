
export enum DynamicContentSettingType {
    Template,
    File
}

export class DynamicContentSetting {
    id: number
    key: string
    type: DynamicContentSettingType
    categorie: string
    valueInt: number
    valueString: string
    valueBool: boolean    
}
export class Menu {
    id: number
    name: string
}

export class MenuEntry {
    id: number
    menuId: number
    pathName: string
    titleLang: string
    icon: string
    displayOrderId: number
}

export class DynamicPage {
    id: number
    name: string
    pathName: string
    contentLang: string
    cssStyle: string
}

export class DynamicTemplate {
    id: number
    name: string
    contentLang: string
    cssStyle: string
}

export class DynamicMedia {
    id: number
    name: string
    url: string
}