
export class Setting {
    id: number
    key: string
    valueInt: number
    valueString: string
    valueBool: boolean 

    public constructor(init?:Partial<Setting>) {
        Object.assign(this, init);
    }   
}

export class SettingCountry {
    id: string
    name: string
    shortname: string
    taxRate: number
    language: string
    
    public constructor(init?:Partial<SettingCountry>) {
        Object.assign(this, init);
    }
}

export class SettingText {
    id: string
    textDe: string
    textEn: string
    
    public constructor(init?:Partial<SettingText>) {
        Object.assign(this, init);
    }
}

export class Translation {
    id: number
    customerUuid: string
    key: string
    country: string
    text: string
    
    public constructor(init?:Partial<Translation>) {
        Object.assign(this, init);
    }
}


export class Service {
    id: number
    title: string
    description: string
    image: string
    orderId: number
}

export class Project {
    id: number
    title: string
    location: string
    description: string
    images: string
}