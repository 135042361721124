import { EventEmitter, Injectable } from "@angular/core";
import { RestApiService } from "./restApi.service";
import { DataParentService } from "./_dataParent.service";
import { Router } from "@angular/router";
import { ApiResponse, ApiResponseStatus } from "../_shared/classes/ApiResponse";
import { JwtService } from "./jwt.service";
import { CookieService } from "ngx-cookie-service";
import { Setting, SettingCountry, SettingText, Translation } from "../_shared/classes/Settings";
import { DynamicContentSetting, DynamicMedia, DynamicPage, DynamicTemplate, Menu, MenuEntry } from "../_shared/classes/DynamicContent";

@Injectable()
export class DataDynamicContentService extends DataParentService  {
    //USER DATA
    public settings: DynamicContentSetting[] = []
    public menues: Menu[] = []
    public menuEntries: MenuEntry[] = []
    public pages: DynamicPage[] = []
    public templates: DynamicTemplate[] = []
    public medias: DynamicMedia[] = []

    public OnSettingsChanged : EventEmitter<DynamicContentSetting[]> = new EventEmitter()
    public OnMenuChanged : EventEmitter<Menu[]> = new EventEmitter()
    public OnMenuEntryChanged : EventEmitter<MenuEntry[]> = new EventEmitter()
    public OnDynamicPageChanged : EventEmitter<DynamicPage[]> = new EventEmitter()
    public OnDynamicTemplateChanged : EventEmitter<DynamicTemplate[]> = new EventEmitter()
    public OnDynamicMediaChanged : EventEmitter<DynamicMedia[]> = new EventEmitter()
        
    constructor(private restApiService: RestApiService,
        private jwtService: JwtService,
        private cookieService: CookieService,
        private router: Router)
    {                
        super(6)
    }  

    public loadData(){
        this.synchSettings()
        this.synchMenu()
        this.synchMenuEntry()
        this.synchDynamicPage()      
        this.synchDynamicTemplate()
        this.synchDynamicMedia()
    }

    public loadedFinish() {        
        
    }

    public synchSettings() {
        this.restApiService.getDynamicSettings().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.settings = response.value as DynamicContentSetting[]
                    this.OnSettingsChanged.emit(this.settings)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);
                
                this.OnSettingsChanged.error(error)
            }
        })
    } 

    public synchMenu() {
        this.restApiService.getMenu().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.menues = response.value as Menu[]
                    this.OnMenuChanged.emit(this.menues)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);
                
                this.OnMenuChanged.error(error)
            }
        })
    }  

    public synchMenuEntry() {
        this.restApiService.getMenuEntries().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.menuEntries = response.value as MenuEntry[]
                    this.OnMenuEntryChanged.emit(this.menuEntries)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);
                
                this.OnMenuEntryChanged.error(error)
            }
        })
    }

    public synchDynamicPage() {
        this.restApiService.getDynamicPages().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.pages = response.value as DynamicPage[]
                    this.OnDynamicPageChanged.emit(this.pages)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);
                
                this.OnDynamicPageChanged.error(error)
            }
        })
    }
    
    public synchDynamicTemplate() {
        this.restApiService.getDynamicTemplates().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.templates = response.value as DynamicTemplate[]
                    this.OnDynamicTemplateChanged.emit(this.templates)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);                
                this.OnDynamicTemplateChanged.error(error)
            }
        })
    }
    
    public synchDynamicMedia() {
        this.restApiService.getDynamicMedias().subscribe({
            next: (response: ApiResponse) => {
                if(response.status == ApiResponseStatus.Success) {
                    this.medias = response.value as DynamicMedia[]
                    this.OnDynamicMediaChanged.emit(this.medias)   
                    this.SetNextLoadStep()                     
                }
            },
            error: (error) => {
                console.log(error);                
                this.OnDynamicMediaChanged.error(error)
            }
        })
    }

    
}