import { Component } from '@angular/core';

@Component({
  selector: 'app-config-dynamic-form-displaytext',
  templateUrl: './config-dynamic-form-displaytext.component.html',
  styleUrls: ['./config-dynamic-form-displaytext.component.scss']
})
export class ConfigDynamicFormDisplaytextComponent {
  displayText: string = ''

  setValue(value: string) {
    this.displayText = value
  }
}
