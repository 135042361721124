import { EventEmitter, Injectable } from "@angular/core";
import { DataSettingService } from "./data.settings.service";
import { ConfigTableDropDownEntry } from "../_khLib/components/config-table/config-table.component";
import { CustomerType, Salutation } from "../_shared/classes/Auth";
import { DataUserManagementService } from "./data.usermanagement.service";
import { DataDynamicContentService } from "./data.dynamicContent.service";

export enum ConfigTableColSelectionType {
    None,
    Bool,

    Salutation,
    Country,
    AccountType,

    Menu,
    MenuEntry,
    Page,
}


@Injectable()
export class HelperService {
    public isDarkTheme = true

    dropDownValueList: ConfigTableDropDownEntry[][] = []

    public OnDataChanged : EventEmitter<ConfigTableDropDownEntry[][]> = new EventEmitter()

    constructor(
        private settingsData: DataSettingService,
        private dataSettings: DataSettingService,
        private dataUser: DataUserManagementService,
        private dataDynamicContent: DataDynamicContentService,
    ) {
        this.initDropDowns() 
    }

    initDropDowns(){
        this.dropDownValueList[ConfigTableColSelectionType.Bool] = this.getDropDownValues(ConfigTableColSelectionType.Bool)
        this.dropDownValueList[ConfigTableColSelectionType.Salutation] = this.getDropDownValues(ConfigTableColSelectionType.Salutation)
        this.dropDownValueList[ConfigTableColSelectionType.Country] = this.getDropDownValues(ConfigTableColSelectionType.Country)
        this.dropDownValueList[ConfigTableColSelectionType.AccountType] = this.getDropDownValues(ConfigTableColSelectionType.AccountType)
        this.dropDownValueList[ConfigTableColSelectionType.Menu] = this.getDropDownValues(ConfigTableColSelectionType.Menu)
        this.dropDownValueList[ConfigTableColSelectionType.MenuEntry] = this.getDropDownValues(ConfigTableColSelectionType.MenuEntry)
        this.dropDownValueList[ConfigTableColSelectionType.Page] = this.getDropDownValues(ConfigTableColSelectionType.Page)
    }

    getAllDropDownValues(): ConfigTableDropDownEntry[][] {                
        return this.dropDownValueList
    }

    getDropDownValues(type: ConfigTableColSelectionType): ConfigTableDropDownEntry[] {
        var keyValues: ConfigTableDropDownEntry[] = []

        switch(type) {
            case ConfigTableColSelectionType.Bool:
                keyValues.push(new ConfigTableDropDownEntry({key: 'true', value: 'bool_yes'}))
                keyValues.push(new ConfigTableDropDownEntry({key: 'false', value: 'bool_no'}))
                break
            case ConfigTableColSelectionType.Salutation:
                keyValues.push(new ConfigTableDropDownEntry({key: Salutation.notSpecified, value: 'saluataion_notSpecified'}))
                keyValues.push(new ConfigTableDropDownEntry({key: Salutation.woman, value: 'saluataion_woman'}))
                keyValues.push(new ConfigTableDropDownEntry({key: Salutation.men, value: 'saluataion_men'}))
                break
            case ConfigTableColSelectionType.Country:
                this.settingsData.countries.forEach(c => keyValues.push(new ConfigTableDropDownEntry({key: c.id, value: c.name})))
                break
            case ConfigTableColSelectionType.AccountType:
                keyValues.push(new ConfigTableDropDownEntry({key: 0, value: 'customers_accountType_private'}))
                keyValues.push(new ConfigTableDropDownEntry({key: 1, value: 'customers_accountType_company'}))
                keyValues.push(new ConfigTableDropDownEntry({key: 2, value: 'customers_accountType_seller'}))
                break
            case ConfigTableColSelectionType.Menu:
                this.dataDynamicContent.menues.forEach(c => keyValues.push(new ConfigTableDropDownEntry({key: c.id, value: c.name})))
                break
            case ConfigTableColSelectionType.MenuEntry:
                this.dataDynamicContent.menuEntries.forEach(c => keyValues.push(new ConfigTableDropDownEntry({key: c.id, value: c.pathName})))
                break
            case ConfigTableColSelectionType.Page:
                this.dataDynamicContent.pages.forEach(c => keyValues.push(new ConfigTableDropDownEntry({key: c.id, value: c.name})))
                break
        }

        return keyValues
    }


}