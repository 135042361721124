<div class="my-2">
    <mat-form-field class="d-flex removeInputBottom">
        <mat-label><span>{{ label | translate}}</span></mat-label>
        <input matInput 
        [placeholder]="placeholder" 
        [(ngModel)]="value" 
        [autocomplete]="autocomplete" 
        (change)="onChangeValue()"
        
        [type]="isPassword ? (showPassword ? 'text' : 'password') : 'text'">
        <button *ngIf="isPassword" matSuffix mat-icon-button (click)="showPassword = !showPassword">
          <mat-icon *ngIf="!showPassword">visibility</mat-icon>
          <mat-icon *ngIf="showPassword">visibility_off</mat-icon>
        </button>
    </mat-form-field>
    <div class="ms-2" style="font-size: 14px;">{{ hint | translate}}</div>
    <div class="ms-2" style="font-size: 14px; color: red;">{{ errorMsg | translate}}</div>
</div>