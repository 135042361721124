
<label [ngClass]="{'darkTheme' : isDarkTheme}">{{ title | translate }}</label>
<br>
<mat-form-field style="width: 100%;">
    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Datum wählen" [formControl]="dateControl" (ngModelChange)="onChangeValue()" readonly
       [min]="minDate" [max]="maxDate">
    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
    <button matSuffix mat-icon-button (click)="onResetDate()">
      <mat-icon [ngClass]="{'darkTheme' : isDarkTheme}">cancel</mat-icon>
    </button>
    <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
       [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
       [touchUi]="touchUi" [enableMeridian]="enableMeridian" 
       [disableMinute]="disableMinute" [hideTime]="hideTime"
       >
    </ngx-mat-datetime-picker>
 </mat-form-field>