import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfigFormElement } from '../../interface/IConfigFormElement';
import { ConfigTableDef, ConfigTableColDef, ConfigTableColType } from '../config-table/config-table.component';

@Component({
  selector: 'app-config-dynamic-form-checkbox',
  templateUrl: './config-dynamic-form-checkbox.component.html',
  styleUrls: ['./config-dynamic-form-checkbox.component.scss']
})
export class ConfigDynamicFormCheckboxComponent implements IConfigFormElement {
  label: string = ''
  hint: string = ''
  value: boolean = false
  disabled: boolean = false
  errorMsg: string = ''

  @Output() valueChange = new EventEmitter<any>()

  setColData(tableData: ConfigTableDef<any>, col: ConfigTableColDef) {
    this.label = tableData.getColumnTitle(col)
  }

  setValue(value: any) {
    this.value = value
  }

  setErrorMessage(msg: string) {
    this.errorMsg = msg
  }

  onChangeValue(): void {
    this.valueChange.emit(this.value)
  }

}
