import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConfigTableDef, ConfigTableColDef, ConfigTableColType, ConfigTableComponent } from 'src/app/_khLib/components/config-table/config-table.component';
import { DynamicPipeType } from 'src/app/_pipes/dynamic.pipe';
import { DataSettingService } from 'src/app/_services/data.settings.service';
import { ErrorService } from 'src/app/_services/error.service';
import { RestApiService } from 'src/app/_services/restApi.service';
import { ApiResponse } from 'src/app/_shared/classes/ApiResponse';
import { Project } from 'src/app/_shared/classes/Settings';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss'
})
export class ProjectsComponent implements OnInit {
  cfgTable = new ConfigTableDef<Project>(Project, {
    tblName: 'project',
    showTblnameInTitle: true,
    showDetailDialog: false,
    //infoDialogTitleFunction: (e) => { return e.name},
    cols: [
      new ConfigTableColDef({name: 'id',          type: ConfigTableColType.Text, showInTable: true, isEditable: false, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'title',         type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'location',         type: ConfigTableColType.Text, showInTable: true, pipe: DynamicPipeType.None}),
      new ConfigTableColDef({name: 'description',         type: ConfigTableColType.TextEditor, showInTable: true, filterable: false, pipe: DynamicPipeType.LimitText}),
      new ConfigTableColDef({name: 'images',         type: ConfigTableColType.MediaList, showInTable: true, filterable: false, isEditable: false, pipe: DynamicPipeType.Image}),
    ], 
    showInlineEditButton: true,
    menuButtons: [

    ],   
    //createBeforeEditData: true,
    onCreateApi: (c): Observable<ApiResponse> => this.restApiService.createProject(c),
    onCreate: (object, result) => this.dataSettings.synchProject(),
    onCreateTextLoading: (): string => 'projectInfoCreate',
    onCreateTextLoaded: (): string => 'projectInfoCreated',
    
    onEditApi: (c): Observable<ApiResponse> => this.restApiService.updateProject(c),
    onEdit: (object, result) => this.dataSettings.synchProject(),
    onEditTextLoading: (): string => 'projectInfoEdit',
    onEditTextLoaded: (): string => 'projectInfoEdited',

    onDeleteApi: (ids): Observable<ApiResponse> => this.restApiService.deleteProject(ids),
    onDelete: (object, result) => this.dataSettings.synchProject(),
    onDeleteTextLoading: (): string => 'projectInfoDelete',
    onDeleteTextLoaded: (): string => 'projectInfoDeleted',

    onSynch: () => this.dataSettings.synchProject(),
    
    uploadImage: (id, data) => this.restApiService.uploadProjectImage(id, data),
    sortImage: (id, sortMode, fileName) => this.restApiService.sortProjectImage(id, sortMode, fileName),
    deleteImage: (id, path) => this.restApiService.deleteProjectImage(id, path),

    listNameColumnFunction: (d) => d.title,
  })
  cfgTableData = this.dataSettings.projects
  @ViewChild('tbl') tbl: ConfigTableComponent

  constructor(private settingsData: DataSettingService,
    private restApiService: RestApiService,
    private snackbar: MatSnackBar,
    private dataSettings: DataSettingService,
    private translateService: TranslateService,
    private errorService: ErrorService) {
      
  }

  ngOnInit(): void {
    this.dataSettings.OnProjectChanged.subscribe((response) => this.tbl.refreshData(response) )
  }  
}
