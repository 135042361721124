<h4>{{'customerHeader' | translate }}</h4>
<mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">list</mat-icon>
        <span class="mx-1">{{'customerAllHeader' | translate }}</span>
        </ng-template>
        <br />        
        
        <app-config-table
            #tblCustomers
            [tableDefinition]="cfgTableCustomers"
            [tableData]="cfgTableCustomersData"
        >
            
        </app-config-table>
        
<br>
<br>
<br>
<br>
    </mat-tab>    
</mat-tab-group>


  