import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { AdminDataBid, AdminDataCollection, AdminDataCollectionItem, AdminDataCustomer, AdminDataVarosId, AdminDataVarosIdOwnership } from "../_shared/classes/AdminData";
import { ApiResponse, ApiResponseLogin } from "../_shared/classes/ApiResponse";
import { Customer, Login } from "../_shared/classes/Auth";
import { environment } from "../../environments/environment"
import { Project, Service, Setting, SettingCountry, SettingText, Translation } from "../_shared/classes/Settings";
import { DynamicContentSetting, DynamicMedia, DynamicPage, DynamicTemplate, Menu, MenuEntry } from "../_shared/classes/DynamicContent";

@Injectable()
export class RestApiService {
    apiUrl = environment.apiPath
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Admin': 'true'
        }),
    } 
    httpOptionsBlob = {
        headers: new HttpHeaders({
            'Content-Type': 'application/blob',
            'Admin': 'true'
        }),
    } 

    httpOptionsEmpty = {
        headers: new HttpHeaders({
            'Admin': 'true'            
        }),
     } 

     
    httpOptionsBlobHeaders =  new HttpHeaders({
        'Admin': 'true'                      
    })

    constructor(
        private http: HttpClient
    ) {}

    setAuthentification(token: string) {                
        this.httpOptions.headers = this.httpOptions.headers.set('Authorization', token == '' ? '' : `Bearer ${token}`)        
        this.httpOptionsBlob.headers = this.httpOptionsBlob.headers.set('Authorization', token == '' ? '' : `Bearer ${token}`)        
        this.httpOptionsBlobHeaders = this.httpOptionsBlobHeaders.set('Authorization', token == '' ? '' : `Bearer ${token}`)        
    }

    //CUSTOMER
    login(data: Login): Observable<ApiResponseLogin> {
        return this.http.post<ApiResponseLogin>(this.apiUrl + '/customer/login', JSON.stringify(data), this.httpOptions)
    }
    checkLogin(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.apiUrl + '/customer/checkLoginAdmin', this.httpOptions)
    }
    getUserDetails(uuid: string): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.apiUrl + '/customer/' + uuid, this.httpOptions)
    }
    getCustomers(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.apiUrl + '/customer/', this.httpOptions)
    }
    createCustomer(data: Customer): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiUrl + '/customer/', JSON.stringify(data), this.httpOptions)
    }
    changeCustomer(data: Customer): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/customer/', JSON.stringify(data), this.httpOptions)
    }
    deleteCustomer(uuids: string[]): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/customer/delete', JSON.stringify(uuids), this.httpOptions)
    }
    deleteFinallyCustomer(uuid: string): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.apiUrl + '/customer/' + uuid, this.httpOptions)
    }
    setCustomerEmailActivation(uuid: string, state: boolean): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/customer/' + uuid + '/emailActivation', JSON.stringify({state: state}), this.httpOptions)
    }
    setCustomerNewsletter(uuid: string, state: boolean): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/customer/' + uuid + '/newsletter', JSON.stringify({state: state}), this.httpOptions)
    }
    createAutoSellCountries(uuid: string): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/customer/' + uuid + '/createAutoSellCountries', JSON.stringify({}), this.httpOptions)
    }
    createStripeCustomer(uuid: string): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/customer/' + uuid + '/createStripeCustomer', JSON.stringify({}), this.httpOptions)
    }
    createStripeConnect(uuid: string): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/customer/' + uuid + '/createStripeConnect', JSON.stringify({}), this.httpOptions)
    }
    deleteStripeConnect(uuid: string): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/customer/' + uuid + '/deleteStripeConnect', JSON.stringify({}), this.httpOptions)
    }

    //DYNAMICCONTENT
    //DYNAMICCONTENT - SETTINGS  
    getDynamicSettings(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.apiUrl + '/dynamicContent/setting/', this.httpOptions)
    }
    changeDynamicSettings(data: DynamicContentSetting): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/dynamicContent/setting/', JSON.stringify(data), this.httpOptions)
    }
    //DYNAMICCONTENT - MENU  
    getMenu(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.apiUrl + '/dynamicContent/menu/', this.httpOptions)
    }
    createMenu(data: Menu): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiUrl + '/dynamicContent/menu/', JSON.stringify(data), this.httpOptions)
    }
    changeMenu(data: Menu): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/dynamicContent/menu/', JSON.stringify(data), this.httpOptions)
    }
    deleteMenu(id: number): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.apiUrl + '/dynamicContent/menu/' + id, this.httpOptions)
    }
    //DYNAMICCONTENT - MENUENTRY  
    getMenuEntries(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.apiUrl + '/dynamicContent/menuentry/', this.httpOptions)
    }
    createMenuEntry(data: MenuEntry): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiUrl + '/dynamicContent/menuentry/', JSON.stringify(data), this.httpOptions)
    }
    changeMenuEntry(data: MenuEntry): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/dynamicContent/menuentry/', JSON.stringify(data), this.httpOptions)
    }
    deleteMenuEntry(id: number): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.apiUrl + '/dynamicContent/menuentry/' + id, this.httpOptions)
    }
    //DYNAMICCONTENT - DYNAMICPAGE  
    getDynamicPages(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.apiUrl + '/dynamicContent/page/', this.httpOptions)
    }
    createDynamicPage(data: DynamicPage): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiUrl + '/dynamicContent/page/', JSON.stringify(data), this.httpOptions)
    }
    changeDynamicPage(data: DynamicPage): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/dynamicContent/page/', JSON.stringify(data), this.httpOptions)
    }
    setDynamicPageHtmlCss(pageId: number, data: any): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/dynamicContent/page/' + pageId + '/htmlCss', JSON.stringify(data), this.httpOptions)
    }
    deleteDynamicPage(id: number): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.apiUrl + '/dynamicContent/page/' + id, this.httpOptions)
    }
    //DYNAMICCONTENT - TEMPLATE  
    getDynamicTemplates(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.apiUrl + '/dynamicContent/template/', this.httpOptions)
    }
    createDynamicTemplate(data: DynamicTemplate): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiUrl + '/dynamicContent/template/', JSON.stringify(data), this.httpOptions)
    }
    changeDynamicTemplate(data: DynamicTemplate): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/dynamicContent/template/', JSON.stringify(data), this.httpOptions)
    }
    setDynamicTemplateHtmlCss(templateId: number, data: any): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/dynamicContent/template/' + templateId + '/htmlCss', JSON.stringify(data), this.httpOptions)
    }
    deleteDynamicTemplate(id: number): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.apiUrl + '/dynamicContent/template/' + id, this.httpOptions)
    }
    //DYNAMICCONTENT - MEDIA  
    getDynamicMedias(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.apiUrl + '/dynamicContent/media/', this.httpOptions)
    }
    createDynamicMedia(data: DynamicMedia): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiUrl + '/dynamicContent/media/', JSON.stringify(data), this.httpOptions)
    }
    changeDynamicMedia(data: DynamicMedia): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.apiUrl + '/dynamicContent/media/', JSON.stringify(data), this.httpOptions)
    }
    deleteDynamicMedia(id: number): Observable<ApiResponse> {
        return this.http.delete<ApiResponse>(this.apiUrl + '/dynamicContent/media/' + id, this.httpOptions)
    }
    createDynamicMediaFile(uuid: string, data): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/dynamicContent/media/${uuid}/upload`, data, this.httpOptionsEmpty)
    }

    // SETTINGS    
    // SETTINGS - Setting
    getSettingList(): Observable<ApiResponse>{
        return this.http.get<ApiResponse>(this.apiUrl + `/settings/setting`, this.httpOptions)
    }
    createSetting(data: Setting): Observable<ApiResponse>{
        return this.http.post<ApiResponse>(this.apiUrl + `/settings/setting`, JSON.stringify(data), this.httpOptions)
    }
    updateSetting(data: Setting): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/settings/setting`, JSON.stringify(data), this.httpOptions)
    }
    deleteSetting(id: number): Observable<ApiResponse>{
        return this.http.delete<ApiResponse>(this.apiUrl + `/settings/setting/${id}`, this.httpOptions)
    }
    
    // SETTINGS - Countries
    getSettingCountryList(): Observable<ApiResponse>{
        return this.http.get<ApiResponse>(this.apiUrl + `/settings/country`, this.httpOptions)
    }
    createSettingCountry(data: SettingCountry): Observable<ApiResponse>{
        return this.http.post<ApiResponse>(this.apiUrl + `/settings/country`, JSON.stringify(data), this.httpOptions)
    }
    updateSettingCountry(data: SettingCountry): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/settings/country`, JSON.stringify(data), this.httpOptions)
    }
    deleteSettingCountry(id: string): Observable<ApiResponse>{
        return this.http.delete<ApiResponse>(this.apiUrl + `/settings/country/${id}`, this.httpOptions)
    }
    
    // SETTINGS - Texts
    getSettingTextList(): Observable<ApiResponse>{
        return this.http.get<ApiResponse>(this.apiUrl + `/settings/texts`, this.httpOptions)
    }
    createSettingText(data: SettingText): Observable<ApiResponse>{
        return this.http.post<ApiResponse>(this.apiUrl + `/settings/texts`, JSON.stringify(data), this.httpOptions)
    }
    updateSettingText(data: SettingText): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/settings/texts`, JSON.stringify(data), this.httpOptions)
    }
    deleteSettingText(id: string): Observable<ApiResponse>{
        return this.http.delete<ApiResponse>(this.apiUrl + `/settings/texts/${id}`, this.httpOptions)
    }

    // SETTINGS - Translations
    getTranslations(): Observable<ApiResponse>{
        return this.http.get<ApiResponse>(this.apiUrl + `/settings/translations/`, this.httpOptions)
    }
    createTranslations(translation: Translation): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/settings/translations/`, JSON.stringify(translation), this.httpOptions)
    }
    updateTranslations(translation: Translation): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/settings/translations/`, JSON.stringify(translation), this.httpOptions)
    }
    deleteTranslations(id: string): Observable<ApiResponse>{
        return this.http.delete<ApiResponse>(this.apiUrl + `/settings/translations/${id}`, this.httpOptions)
    }


    // PROJECTS
    getProjects(): Observable<ApiResponse>{
        return this.http.get<ApiResponse>(this.apiUrl + `/settings/project/`, this.httpOptions)
    }
    createProject(project: Project): Observable<ApiResponse>{
        return this.http.post<ApiResponse>(this.apiUrl + `/settings/project/`, JSON.stringify(project), this.httpOptions)
    }
    updateProject(project: Project): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/settings/project/`, JSON.stringify(project), this.httpOptions)
    }
    deleteProject(id: string): Observable<ApiResponse>{
        return this.http.delete<ApiResponse>(this.apiUrl + `/settings/project/${id}`, this.httpOptions)
    }
    uploadProjectImage(id: number, data): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/settings/project/${id}/images`, data, this.httpOptionsEmpty)
    }
    sortProjectImage(id: number, sortMode: number, fileName: string): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/settings/project/${id}/images/${sortMode}/${fileName}`, null, this.httpOptionsEmpty)
    }
    deleteProjectImage(id: number, path: string): Observable<ApiResponse>{
        return this.http.delete<ApiResponse>(this.apiUrl + `/settings/project/${id}/images/${path}`, this.httpOptions)
    }

    // SERVICES
    getServices(): Observable<ApiResponse>{
        return this.http.get<ApiResponse>(this.apiUrl + `/settings/service/`, this.httpOptions)
    }
    createService(service: Service): Observable<ApiResponse>{
        return this.http.post<ApiResponse>(this.apiUrl + `/settings/service/`, JSON.stringify(service), this.httpOptions)
    }
    updateService(service: Service): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/settings/service/`, JSON.stringify(service), this.httpOptions)
    }
    deleteService(id: string): Observable<ApiResponse>{
        return this.http.delete<ApiResponse>(this.apiUrl + `/settings/service/${id}`, this.httpOptions)
    }
    createServiceImage(uuid: string, data): Observable<ApiResponse>{
        return this.http.put<ApiResponse>(this.apiUrl + `/settings/service/${uuid}/upload`, data, this.httpOptionsEmpty)
    }
   



    downloadImage(path: string): Observable<any>{        
        return this.http.get<any>(path, { responseType: 'blob' as 'json'})
    }

    
    
    



    
}