import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfigTableColDef, ConfigTableColType, ConfigTableDef } from '../config-table/config-table.component';
import { IConfigFormElement } from '../../interface/IConfigFormElement';

@Component({
  selector: 'app-config-dynamic-form-textarea',
  templateUrl: './config-dynamic-form-textarea.component.html',
  styleUrls: ['./config-dynamic-form-textarea.component.scss']
})
export class ConfigDynamicFormTextareaComponent implements IConfigFormElement {
  label: string = ''
  rows: number = 5
  hint: string = ''
  value: any
  placeholder: string = ''
  errorMsg: string = ''

  @Output() valueChange = new EventEmitter<any>()

  showPassword: boolean = false

  setColData(tableData: ConfigTableDef<any>, col: ConfigTableColDef) {
    this.label = tableData.getColumnTitle(col)    
  }

  setValue(value: any) {
    this.value = value
  }

  setErrorMessage(msg: string) {
    this.errorMsg = msg
  }

  onChangeValue(): void {
    this.valueChange.emit(this.value)
  }
}
