import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataUserManagementService } from 'src/app/_services/data.usermanagement.service';
import { ApiResponseLogin, ApiResponseStatus } from 'src/app/_shared/classes/ApiResponse';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent {
  showPassword: boolean = false
  accountCreated: boolean = false
  unauthorized: boolean = false
  errorText: string = ''

  loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
  })

  constructor(
    private route: ActivatedRoute,
    private dataUserManagement: DataUserManagementService,
    private router: Router
  ){}

  ngOnInit(){
    if(this.dataUserManagement.getIsLoggedIn()){
      this.router.navigate([''])
    } else {
      this.route.queryParams.subscribe((params) => {
        this.accountCreated = params['regSuccess'] == 1
      })
    }
  }

  onSubmit(){
    this.dataUserManagement.loginUser({
      email: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value,
    }).subscribe({
      next: (response: ApiResponseLogin) => {        
        if(response.status == ApiResponseStatus.Success) {          
          this.router.navigate([''])
          this.unauthorized = false
        } else {
          this.unauthorized = true
          this.errorText = response.message
        }
      },
      error: (error) => {       
        this.unauthorized = true
      }
    })   
  }
}
