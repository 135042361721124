import { Component } from '@angular/core';

@Component({
  selector: 'app-config-dynamic-form-row',
  templateUrl: './config-dynamic-form-row.component.html',
  styleUrls: ['./config-dynamic-form-row.component.scss']
})
export class ConfigDynamicFormRowComponent {

}
